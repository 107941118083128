.fancybox-caption {
    display: none;
}

.fancybox-custom-caption {
    position: absolute;
    top: calc(100% + 10px);
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    width: 100%;
}