.chackout-page-section {
	padding: 100px 0 ;
	.woocommerce-notices-wrapper {
		display: none ;
	}
	h2 {
		color: $black;
		font-size: 40px;
		margin: 0 0 40px 0 ;
	}
	.shipping_address {
		padding-top: 47px ;
	}
	.form-client {
		margin: 38px 0 0 0 ;
	}
	.form-billing {
		.woocommerce-billing-fields {
			margin: 35px 0 0 0 ;
		}
	}
	.shipping_address,
	.form-billing,
	.form-client .woocommerce-client-fields {
		h3 {
			color: $black;
			font-size: 30px;
			font-weight: 700;
			margin: 0 0 35px 0 ;
		}
	}
	.page-row {
		display: flex;
		flex-wrap: wrap;
		padding-left: 5px;
		padding-right: 5px;
		.column {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.checkout-left-col {
		width: 49%;
	}
	.checkout-right-col {
		width: 51%;
	}
	.woocommerce-form-coupon-toggle {
		display: none;
	}
	.woocommerce-before-billing-fields__field-wrapper {
		.form-row {
			padding: 0 0 5px 0 ;
			margin: 0 0 0 0 ;
			.required {
				display: none;
			}
			label {
				color: $black;
				font-family: $font ;
				font-size: 18px;
				line-height: 22px ;
				font-weight: 500;
				margin: 0 0 17px 0 ;
			}
			.woocommerce-input-wrapper {
				display: block;
				border-radius: 5px;
				border: 1px solid #cacaca;
				background-color: $white;
				padding: 15px 25px ;
				margin: 0 0 13px 0 ;
				display: flex;
				flex-wrap: wrap;
				
				.single-radio {
					min-width: 175px ;
					input {
						display: none;
						&:checked {
							~ label {
								&:before {
									background: $color_2 ;
								}	
							}
						}
					}
					label {
						color: #1f1f1f;
						font-size: 15px;
						line-height: 21px;
						font-weight: 400;
						font-family: $font ;
						display: flex;
						align-items: center ;
						margin: 0 0 0 0 ;
						&:before {
							transition: all .3s ease-in-out ;
							content: '' ;
							display: inline-block;
							width: 20px;
							height: 20px;
							box-shadow: 0 0 0 1px #cacaca;
							margin: 0 17px 0 0 ;
							border: 3px solid $white ;
							box-sizing: border-box ;
						}
					}
				}
			}
		}
	}
	.form-client,
	.form-shipping,
	.form-billing {
		.woocommerce-client-fields__field-wrapper,
		.woocommerce-shipping-fields__field-wrapper,
		.woocommerce-billing-fields__field-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.form-row {
			padding: 0 0 0 0 ;
			margin: 0 0 15px 0 ;
			float: none ;
			width: 100%;
			&.form-row-first {
				width: 49%;
			}
			&.form-row-last {
				width: 49%;
			}
			&.woocommerce-invalid {
				input {
					border: 1px solid red ;
				}
			}
			input {
				padding: 12px 17px ;
				border-radius: 3px;
				border: 1px solid #cacaca;
				background-color: $white;
				font-size: 15px;
			}
		}
	}
	.form-client {
		h3 {
			margin: 0 0 7px 0 ;
		}
	}
	.woocommerce-form__label-for-checkbox {
		
		.woocommerce-form__input-checkbox {
			display: none;
			&:checked ~ span {
				&:before {
					background-image: url('../img/checkbox.svg') ;
					
				}
			}
		}
		span {
			color: #666666;
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			min-height: 22px ;
			display: block;
			position: relative;
			padding: 0 0 0 42px ;
			&:before {
				position: absolute ;
				content: '' ;
				width: 22px;
				height: 22px;
				top: 0;
				left: 0;
				background-color: $white ;
				border: 1px solid #cacaca;
				background-repeat: no-repeat ;
				background-position: center ;
				background-size: 10px auto;
			}
		}
	}
	.form-additional {
		padding: 45px 0 0 0 ;
		p {

			padding: 0 0 0 0 ;
			margin: 0 0 35px 0 ;
		}
		label {
			color: #666666;
			font-size: 18px;
			font-weight: 500;
			font-family: $font ;
			display: block;
			margin: 0 0 18px 0 ;
		}
		textarea.input-text {
			height: 109px ;
			border-radius: 3px;
			border: 1px solid #cacaca;
			background-color: $white;
			resize: none ;
			padding: 12px 17px ;
			&::placeholder {
				font-family: $font ;
				color: #666666;
				font-size: 15px;
			}
		}
	}
	#payment.woocommerce-checkout-payment {
		background: transparent ;
		.form-row {
			padding: 0 0 0 0 ;
			display: flex;
			justify-content: space-between;
			&:before,&:after {
				display: none;
			}
		}
		a.button {
			background: $color_2;
		}
		button.button {
			background: $main_color;
			
		}
		.button {
			width: 48%;
			padding: 18px 5px ;
			outline: none ;
			color: $white;
			text-align: center;
			font-size: 17px;
			font-weight: 700;
			text-transform: uppercase;
			border-radius: 5px;
			font-weight: 400;
			font-family: $font ;
			transition: all .3s ease-in-out ;
			&:hover {
				opacity: 0.7;
			}
		}
	}
	.checkout-right-col-info {
		border-radius: 5px;
		border: 1px solid #cacaca;
		background-color: $white;
		margin: 39px 0 0 0 ;
		padding: 40px 25px 40px 25px ;
		h3 {
			color: $black;
			font-family: $font ;
			font-size: 21px;
			font-weight: 500;
			line-height: 35px;
			margin: 0 0 14px 0 ;
		}
		.woocommerce-checkout-review-order-table {
			border: none;
			.variation {
				margin: 0;
				padding: 0;
				font-size: 12px;
				list-style: none;
			}
			.cart_item {
				display: flex;
				align-items: center ;
				border-radius: 5px;
				border: 1px solid #eeeeee;
				background-color: #fafafa;
				margin: 0 0 15px 0 ;
				padding: 27px 17px ;
				.product-thumbnail {
					width: 80px;
					img {
						max-width: 80px ;
						max-height: 80px ;
					}
				}
				.product-name {
					width: 40%;
					padding: 0 7px ;
					a {
						color: $black;
						font-family: $font ;
						font-size: 17px;
						font-weight: 400;
						line-height: 23px;
						&:hover {
							color: $main_color ;
						}
					}
				}
				.product-quantity {
					margin: 0 auto ;
					display: flex;
					align-items: center ;
					input {
						width: 36px;
						height: 23px;
						border-radius: 3px;
						border: 1px solid #979797;
						background-color: #fafafa;
						margin: 0 5px ;
						color: $black;
						font-family: $font ;
						font-size: 12px;
						font-weight: 700;
					}
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						/* display: none; <- Crashes Chrome on hover */
						-webkit-appearance: none;
						margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
					}

					input[type=number] {
						-moz-appearance:textfield; /* Firefox */
					}
					.plus-minus-btn {
						font-size: 22px;
						line-height: 22px ;
						color: $black;
						font-family: $font ;
						font-weight: 700;
					}
				}
				.product-subtotal {
					margin: 0 auto ;
					color: $black;
					font-family: $font ;
					font-size: 17px;
					font-weight: 300;
					line-height: 30px;
				}
				.product-remove {
					margin: 0 0 0 auto ;
					display: flex;
					align-content: center ;
					.js-remove-from-checkout {
						display: inline-block;
						width: 15px;
						height: 15px;
						font-size: 0;
						background: url('../img/remove-from-cart.svg') no-repeat center ;
						&:hover {
							filter:  invert(100%);
						}
					}
				}
			}
		}
		.cart-total-info {
			padding: 10px 0 0 0 ;
			.cart-subtotal,
			.cart-shipping-info,
			.tax-rate,
			.fee,
			.order-total {
				display: flex;
				justify-content: space-between;
				color: $black;
				font-family: $font ;
				font-size: 18px;
				margin: 0 0 12px 0 ;
				font-weight: 400;
				.woocommerce-shipping-methods {
					li {
						margin: 0 0 0 0 !important;
					}
					.woocommerce-Price-amount {
						font-weight: 400 !important;
					}
				}
			}
			.order-total {
				color: $black;
				font-size: 18px;
				font-weight: 700;
				strong {
					font-weight: 700;
				}
			}
		}
	}
}


@media only screen and (max-width: 991px) {
	.chackout-page-section {
		.checkout-right-col,
		.checkout-left-col {
			width: 100%;
		}
		.checkout-right-col {
			order: 1 ;
			
		}
		.page-row {
			.checkout-right-col {
				padding-left: 0;
				padding-right: 0;

			}
		}
		.checkout-left-col {
			order: 2 ;
		}
		.checkout-right-col-info {
			margin: 0 0 50px 0;
		}
	}

}
@media only screen and (max-width: 767px) {
	.chackout-page-section {
		padding: 40px 0 55px 0 ;
		h2 {
			font-size: 30px;
			line-height: 36px ;
			margin: 0 0 34px 0 ;
		}
		.page-row .column {
			&.checkout-left-col {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		.form-client {
			margin: 48px 0 0 0 ;
		}
		.form-client {
			h3 {
				margin: 0 0 9px 0 ;
			}
		}
		.woocommerce-before-billing-fields__field-wrapper {
			.form-row {
				.woocommerce-input-wrapper {
					padding: 15px 25px 3px 25px;
					.single-radio {
						margin: 0 0 12px 0 ;
						min-width: 50% ;
					}
				}
			}
		}
		.shipping_address,
		.form-billing,
		.form-client .woocommerce-client-fields {
			h3 {
				font-size: 26px;
				margin: 0 0 30px 0 ;
			}
		}
		.form-client,
		.form-shipping,
		.form-billing {
			.form-row {
				&.form-row-first {
					width: 100%;
				}
				&.form-row-last {
					width: 100%;
				}
			}
		}
		.checkout-right-col-info {
			border: none;
			padding: 0 0 0 0 ;
			h3 {
				display: none;
			}
			.woocommerce-checkout-review-order-table {
				.cart_item .product-quantity {
					display: none;
				}
				.cart_item {
					padding: 8px 8px;
					border-radius: 4px ;
					margin: 0 0 7px 0 ;
					.product-thumbnail {
						width: 58px;
						img {
							max-width: 58px;
							max-height: 58px;
						}
					}
					.product-name {
						padding: 0 6px ;
						width: 50% ;
						a {
							font-size: 14px;
							font-weight: 300;
							line-height: 17px;
						}
					}
					.product-subtotal {
						font-size: 15px;
						line-height: 24px;
					}
				}
			}
			.cart-total-info {
				padding: 10px 20px 0 20px ;
				.cart-subtotal,
				.cart-shipping-info,
				.tax-rate,
				.fee,
				.order-total {
					font-size: 16px;
					margin: 0 0 14px 0 ;
					.woocommerce-shipping-methods {
						li {
							margin: 0 0 0 0 !important;
						}
						.woocommerce-Price-amount {
							font-weight: 400 !important;
						}
					}
				}
				.order-total {
					color: $black;
					font-size: 18px;
					font-weight: 700;
					strong {
						font-weight: 700;
					}
				}
			}
		}
		.form-additional {
			p {
				margin: 0 0 45px 0 ;
			}
			textarea.input-text {
				height: 141px;
			}
		}
		.form-row {
			flex-wrap: wrap;
		}
		#payment.woocommerce-checkout-payment {
			.button {
				width: 100%;
				margin: 0 0 15px 0 ;
				font-size: 15px;
				padding: 19px 5px;
			}
		}
	}
}
@media only screen and (max-width: 575px) {

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

}

.wc-svea-checkout-page-inner {
	.wc-svea-checkout-order-details {
		display: none;
	}

	.wc-svea-checkout-checkout-module {
		padding-left: 0;
		width: 100%;
	}
}