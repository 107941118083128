/*------------------------------------*\
    MAIN
\*------------------------------------*/

/* html element 62.5% font-size for REM use */

/* clear */
.clear:before,
.clear:after {
    content:' ';
    display:table;
}

.clear:after {
    clear:both;
}
img {
	max-width:100%;
	height: auto;
}
a {
	color:#444;
	text-decoration:none;
}
a:hover,
a:active,
a:focus,
input:focus {
	outline:0;
}


/*------------------------------------*\
    WORDPRESS CORE
\*------------------------------------*/

.alignnone {
	margin:5px 20px 20px 0;
}
.aligncenter,
div.aligncenter {
	display:block;
	margin:5px auto 5px auto;
}
.alignright {
	float:right;
	margin:5px 0 20px 20px;
}
.alignleft {
	float:left;
	margin:5px 20px 20px 0;
}
a img.alignright {
	float:right;
	margin:5px 0 20px 20px;
}
a img.alignnone {
	margin:5px 20px 20px 0;
}
a img.alignleft {
	float:left;
	margin:5px 20px 20px 0;
}
a img.aligncenter {
	display:block;
	margin-left:auto;
	margin-right:auto;
}
.wp-caption {
	background:#FFF;
	border:1px solid #F0F0F0;
	max-width:96%;
	padding:5px 3px 10px;
	text-align:center;
}
.wp-caption.alignnone {
	margin:5px 20px 20px 0;
}
.wp-caption.alignleft {
	margin:5px 20px 20px 0;
}
.wp-caption.alignright {
	margin:5px 0 20px 20px;
}
.wp-caption img {
	border:0 none;
	height:auto;
	margin:0;
	max-width:98.5%;
	padding:0;
	width:auto;
}
.wp-caption .wp-caption-text,
.gallery-caption {
	font-size:11px;
	line-height:17px;
	margin:0;
	padding:0 4px 5px;
}
.sticky {

}
.bypostauthor {

}

/*------------------------------------*\
    PRINT
\*------------------------------------*/

@media print {
	* {
		background:transparent !important;
		color:#000 !important;
		box-shadow:none !important;
		text-shadow:none !important;
	}
	a,
	a:visited {
		text-decoration:underline;
	}
	a[href]:after {
		content:" (" attr(href) ")";
	}
	abbr[title]:after {
		content:" (" attr(title) ")";
	}
	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content:"";
	}
	pre,blockquote {
		border:1px solid #999;
		page-break-inside:avoid;
	}
	thead {
		display:table-header-group;
	}
	tr,img {
		page-break-inside:avoid;
	}
	img {
		max-width:100% !important;
	}
	@page {
		margin:0.5cm;
	}
	p,
	h2,
	h3 {
		orphans:3;
		widows:3;
	}
	h2,
	h3 {
		page-break-after:avoid;
	}
}
