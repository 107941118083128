.fields-for-request {
    &__group {
        padding-right: 30px;
    }
    &__label {
        color: #000;
        font-size: 17px;
        font-weight: 500;
        display: block;
        margin: 0 0 9px 0;
    }
    &__input {
        width: 100%;
        border-radius: 3px;
        border: 1px solid #cacaca;
        background-color: #fff;
        color: #000;
        font-family: Helvetica,sans-serif;
        font-size: 17px;
        font-weight: 300;
        appearance: none;
        padding: 11px 16px;
        margin: 0 0 15px 0;
    }
}

@media (max-width: 767px) {
    .fields-for-request {
        padding-top: 30px;
        margin-top: 20px;
        &__group {
            padding-right: 0;
        }
    }
}