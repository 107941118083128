/* cyrillic-ext */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lobster Regular'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zo-mM5Ez.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lobster Regular'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoamM5Ez.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lobster Regular'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zo2mM5Ez.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lobster Regular'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoymM5Ez.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lobster Regular'), local('Lobster-Regular'), url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoKmMw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}




// @font-face {
//   font-family: 'Helvetica';
//   src: url('../fonts/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'), 
//   url('../fonts/HelveticaNeueMedium.woff') format('woff'),
//   url('../fonts/HelveticaNeueMedium.ttf')  format('truetype'), 
//   url('../fonts/HelveticaNeueMedium.svg#HelveticaNeueMedium') format('svg');
//   font-weight: 600;
//   font-style: normal;
// }



// @font-face {
//   font-family: 'Helvetica';
//   src: url('../fonts/HelveticaNeueRegular.eot?#iefix') format('embedded-opentype'), 
//   url('../fonts/HelveticaNeueRegular.woff') format('woff'),
//   url('../fonts/HelveticaNeueRegular.ttf')  format('truetype'),
//   url('../fonts/HelveticaNeueRegular.svg#HelveticaNeueRegular') format('svg');
//   font-weight: 400;
//   font-style: normal;
// }



// @font-face {
//   font-family: 'Helvetica';
//   src: url('../fonts/HelveticaNeueLight.eot?#iefix') format('embedded-opentype'),  
//   url('../fonts/HelveticaNeueLight.woff') format('woff'), 
//   url('../fonts/HelveticaNeueLight.ttf')  format('truetype'), 
//   url('../fonts/HelveticaNeueLight.svg#HelveticaNeueLight') format('svg');
//   font-weight: 400;
//   font-style: normal;
// }


@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue.woff') format('woff'), 
  url('../fonts/HelveticaNeue.ttf')  format('truetype'), 
  url('../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/HelveticaNeueBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeueBold.woff') format('woff'),
  url('../fonts/HelveticaNeueBold.ttf')  format('truetype'), 
  url('../fonts/HelveticaNeueBold.svg#HelveticaNeueBold') format('svg');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'), 
  url('../fonts/HelveticaNeueMedium.woff') format('woff'),
  url('../fonts/HelveticaNeueMedium.ttf')  format('truetype'), 
  url('../fonts/HelveticaNeueMedium.svg#HelveticaNeueMedium') format('svg');
  font-weight: 500;
  font-style: normal;
}
