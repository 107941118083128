
.inspiration-content {
	padding: 140px 0 ;
	.inspiration-page-title {
		color: $main_color;
		font-family: $font-lobster;
		font-size: 40px;
		line-height: 46px ;
		font-weight: 400;
		margin: 0 0 44px 0 ;
	}
}
.category-filter-list {
	display: flex;
	flex-wrap: wrap;
	list-style: none ;
	padding: 0 100px ;
	margin: 0 0 76px 0 ;
	justify-content: center ;
	li {
		margin: 4px 4px ;
		a {
			display: inline-block;
			color: $black;
			font-size: 11px;
			font-weight: 400;
			letter-spacing: 0.55px;
			text-transform: uppercase;
			border: 1px solid #cecece;
			text-align: center;
			padding: 8px 15px ;
			min-width: 130px ;
			&.active,&:hover {
				background: $main_color ;
				color: $white ;
				border: 1px solid $main_color ;
			}
		}
	}
}

.category-gallery-list {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 0 0 ;
	margin: 0 0 0 0 ;
	list-style: none ;
	.a-hover{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		opacity: 0;
		transition: all.3s;
	}
	li {
		width: 25%;
		margin: 0 0 30px 0 ;
		.gallery-image-container {
			overflow: hidden;
		}
		.category-gallery-image {
			padding: 70% 0 0 0 ;
			display: block;
			position: relative;
			@extend %bgcover ;
			cursor: pointer ;
			&:hover {
				transform: scale(1.15);
				.a-hover{
					opacity: 1;
				}
			}
			img {
				display: none;
			}
		}
		.gallery-image-description {
			display: inline-block;
			margin-top: 10px;
			font-size: 15px;
			display: none;
		}
	}
}

.load-more-row {
	padding: 50px 0 0 0 ;
}
.load-more-inspiration {
	color: $black;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.88px;
	display: none;

	&:hover {
		color: $main_color ;
		.load-dots {
			span {
				background: $white ;
				i {
					background: $main_color ;
				}
			}
		}
	}
	.load-dots {
		display: inline-block;
		vertical-align: middle;
		margin: 0 9px ;
		span {
			display: flex ;
			justify-content: center;
			align-items: center ;
			width: 45px;
			height: 32px;
			background-color: $main_color;
			border: 2px solid $main_color ;
			text-align: center;
			transition: all .3s ease-in-out ;
			i {
				display: inline-block;
				vertical-align: top;
				width: 5px;
				height: 5px;
				background-color: $white;
				margin: 0 1.5px ;
				transition: all .3s ease-in-out ;
			}
		}
	}
}
.fb-caption{
	position: absolute;
	left: 0;
	right: 0;
	bottom: -30px;
	z-index: 99996;
	pointer-events: none;
	text-align: center;
	transition: opacity 200ms;
	background: none;
	color: white;
  }
@media only screen and (max-width: 991px) {

}
@media only screen and (max-width: 767px) {
	.inspiration-content {
		padding: 63px 0 ;
		.inspiration-page-title {
			font-size: 25px ;
			line-height: 31px ;
		}
	}
	.category-filter-list {
		padding: 0 25px ;
		display: flex;
		justify-content: space-between;
		margin: 0 0 19px 0 ;
		li {
			width: 48% ;
			margin: 0 0 11px 0 ;
			a {
				display: block;
				text-align: center;
				font-size: 12px;
				padding: 7px 10px;
				word-wrap: break-word ;
			}
		}
	}
	.category-gallery-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 10px ;
		li {
			width: 48.5%;
			padding: 0 0 0 0 ;
			margin: 0 0 10px 0 ;
		}
	}
}



@media only screen and (min-width: 641px) and (max-width: 1024px) {

}
@media only screen and (max-width: 500px) {
	
}