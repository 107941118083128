.tax-product_cat {
	.current-menu-parent {
		> a {
			color: #f87d17 !important;
			font-weight: 700;
		}
		.sub-menu {
			display: block;
			li {
				&.current-menu-item {
					> a {
						color: #f87d17;
						font-weight: 700;
					}
				}
			}
		}
	}
}
.product-list-title {
	display: block;
	color: $black;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 25px 0 ;
	position: relative;
}
.toggle-category-list {
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
	background: url('../img/plus.svg') no-repeat center ;
	background-size: 16px auto ;
	&:hover {
		filter: invert(100%);
	}
	&.active {
		background: url('../img/minus.svg') no-repeat center ;
		filter: unset !important ;
	}
}
.category-sidebar-container {
	box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.03);
	border: 1px solid #eeeeee;
	background-color: $white;
	padding: 20px 16px ;

}

.sidebar-menu {
	margin: 0 0 0 0 ;
	padding: 5px 0 0 0 ;
	list-style: none ;
	border-top: 1px solid #eeeeee;
	a {
		// display: block;
		&:hover {
			color: #f87d17;
		}
	}
	> li {
		position: relative;
		padding: 0 0 9px 26px ;
		&.active,&.active-2 {
			> a {
				color: #f87d17;
				font-weight: 700;
				background: #F6F6F6 ;
			}
		}
		> a {
			display: block;
			color: #666666;
			font-size: 14px;
			line-height: 26px;
			padding: 0 0 0 7px ;

		}
	}
	.sub-menu {
		list-style: none ;
		margin: 0 0 0 0px ;
		padding: 13px 0 14px 11px ;
		display: none;
		.sub-menu {
			padding: 13px 0 14px 40px ;
			display: block ;
		}
		li {
			a {
				position: relative;
				padding: 0 0 0 16px;
				color: #666666;
				font-size: 13px;
				display: inline-block;
				line-height: 23px;
				&:before {
					left: 0 ;
					top: 7px ;
					content: '' ;
					position: absolute ;
					background: url('../img/arrow-right.svg') no-repeat center ;
					background-size: 5px auto ;
					width: 5px;
					height: 8px;
				}
				&:hover {
					color: $main_color ;
				}
			}
		}
	}
	.plus-minus {
		width: 26px;
		height: 26px;
		background-color: #f6f6f6;
		position: absolute;
		top: 0px;
		left: 0;
		cursor: pointer ;
		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto ;
			content: '' ;
			width: 10px;
			height: 10px;
			background: url('../img/plus.svg') no-repeat center ;
			background-size: 10px auto ;
			transition: all .3s ease-in-out ;
		}
		&:hover {
			&:before {
				-webkit-filter: invert(100%);
			}
		}
		&.active {
			&:before {
				background: url('../img/minus.svg') no-repeat center ;
				background-size: 10px auto ;
			}
			&:hover {
				&:before {
					filter: unset !important;
				}
			}
		}
	}
}



@media only screen and (min-width: 768px) {

}
@media only screen and (max-width: 767px) {
	.sidebar-menu.tax-product_cat {
		display: none;
		margin: 25px 0 0 0 ;
	}
	.product-list-title {
		margin: 0 0 0 0 ;
	}
}



@media only screen and (min-width: 641px) and (max-width: 1024px) {

}
@media only screen and (max-width: 500px) {

}