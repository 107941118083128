@mixin media-max($breakpoint) {
	@media (max-width: $breakpoint) { @content; }
}
@mixin media-min($breakpointMin) {
	@media (min-width: $breakpointMin) { @content; }
}

@mixin media-min-max($breakpointMin, $breakpointMax) {
	@media screen and (min-width: $breakpointMin) and (max-width: $breakpointMax) { @content; }
}

@mixin media-max-h($breakpoint) {
	@media (max-height: $breakpoint) { @content; }
}

html {
	color: #0a2022;
	font-size: $font-size-base;
	font-weight: 400;
}

* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	&:before,&:after {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
}


html, body {
	margin: 0;
	padding: 0;
	background-color: $grey;
	font-weight: 400 ;
	font-family: $font ;
}

body {
	min-width: 320px;
	overflow-x: hidden;
	position: relative;
}
.wrapper {
	max-width: 1190px;
	width: 100%;
	padding: 0 25px;
	margin: auto;
}

a {
	color: $main_color;
	transition: all .3s ease-in-out ;
}

h1, h2, h3, h4, h5, h6 {
	color: $black;
	font-weight: 700;
}

h1 {

}
h2 {
	font-size: 35px;
	line-height: 41px;
	margin: 0 0 12px 0 ;
}
h3 {
	font-size: 30px;
	line-height: 36px;
	margin: 0 0 13px 0 ;
}
h4 {
	font-size: 25px;
	line-height: 34px;
	margin: 0 0 14px 0 ;
}
h5 {
	font-size: 20px;
	line-height: 26px;
	margin: 0 0 16px 0 ;
}
h6 {
	font-size: 18px;
	line-height: 24px ;
	margin: 0 0 17px 0 ;
}

p {
	color: $black;
	font-size: 17px;
	line-height: normal;
	margin: 0 0 15px 0 ;
	a {
		color: #ff6602;
		box-shadow: inset 0 -1px 0 #ff6602 ;
		&:hover {
			box-shadow: none ;
		}
	}
}

.container-mini {
	@media screen and (min-width: 760px){
		max-width: 730px;
	}
}

.container-medium {
	@media screen and (min-width: 1150px){
		max-width: 1120px;
	}
}

.container-big {
	@media screen and (min-width: 1600px){
		max-width: 1570px;
	}
}

.text-lime {
	color: #bdd146;
}

.d-none-sm {
	display: none;

	@media screen and (min-width: 576px) {
		display: block;
	}
}

.hidden {
	display: none;
}

.text-trn-inherit {
	text-transform: inherit !important;
}

// .mb-15 {
// 	margin-bottom: 15px;
// }
// .mb-18 {
// 	margin-bottom: 18px;
// }
// .mb-6 {
// 	margin-bottom: 6px;
// }

/* text color hover */
.column,.columns {
	padding-left: 15px;
	padding-right: 15px;
}
.page-row {
	max-width: 1250px ;
	margin: 0 auto 0;
}
.text-center {
	text-align: center;
}

/* ------- Basic Styles ------------------- */

 // Preloader 
 .preloader {
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	position: fixed;
 	top: 0;
 	left: 0;
 	background: $white ;
 	width: 100%;
 	height: 100%;
 	z-index: 1000;
 	&--hidden{
 		opacity: 0;
 		visibility: hidden;
 	}
 }

 .lds-ellipsis {
 	display: inline-block;
 	position: relative;
 	width: 64px;
 	height: 64px;
 }
 .lds-ellipsis div {
 	position: absolute;
 	top: 27px;
 	width: 11px;
 	height: 11px;
 	border-radius: 50%;
 	background: $main_color ;
 	animation-timing-function: cubic-bezier(0, 1, 1, 0);
 }
 .lds-ellipsis div:nth-child(1) {
 	left: 6px;
 	animation: lds-ellipsis1 0.6s infinite;
 }
 .lds-ellipsis div:nth-child(2) {
 	left: 6px;
 	animation: lds-ellipsis2 0.6s infinite;
 }
 .lds-ellipsis div:nth-child(3) {
 	left: 26px;
 	animation: lds-ellipsis2 0.6s infinite;
 }
 .lds-ellipsis div:nth-child(4) {
 	left: 45px;
 	animation: lds-ellipsis3 0.6s infinite;
 }
 @keyframes lds-ellipsis1 {
 	0% {
 		transform: scale(0);
 	}
 	100% {
 		transform: scale(1);
 	}
 }
 @keyframes lds-ellipsis3 {
 	0% {
 		transform: scale(1);
 	}
 	100% {
 		transform: scale(0);
 	}
 }
 @keyframes lds-ellipsis2 {
 	0% {
 		transform: translate(0, 0);
 	}
 	100% {
 		transform: translate(19px, 0);
 	}
 }

 @media only screen and (min-width: 992px) {
 	.show-for-middle {
 		display: none;
 	}
 	.hide-for-middle {
 		display: block;
 	}
 }
 @media only screen and (max-width: 991px) {
 	.show-for-middle {
 		display: block ;
 	}
 	.hide-for-middle {
 		display: none;
 	}
 }

 @media only screen and (min-width: 768px) {
 	.show-for-small {
 		display: none;
 	}
 }
 @media only screen and (max-width: 767px) {
 	h2 {
 		font-size: 30px;
 		line-height: 36px;
 		margin: 0 0 17px 0 ;
 	}
 	h3 {
 		font-size: 26px;
 		line-height: 32px;
 		margin: 0 0 18px 0 ;
 	}
 	h4 {
 		font-size: 22px;
 		line-height: 28px;
 		margin: 0 0 19px 0 ;
 	}
 	h5 {
 		font-size: 19px;
 		line-height: 25px;
 		margin: 0 0 20px 0 ;
 	}
 	h6 {
 		font-size: 17px;
 		line-height: 23px ;
 		margin: 0 0 19px 0 ;
 	}

 	p {
 		color: $black;
 		font-size: 15px;
 		line-height: 24px;
 		margin: 0 0 20px 0 ;
 	}
 	.column,.columns {
 		padding-left: 25px;
 		padding-right: 25px;
 	}
 	.show-for-small {
 		display: block;
 	}
 	.hide-for-small {
 		display: none;
 	}
 }
 @media only screen and (min-width: 641px) and (max-width: 1024px) {

 }
 @media only screen and (max-width: 500px) {

 }