div.wpcf7 {
	margin: 50px 0;
	.wpcf7-form-row {
		.form-control {
			margin: 0 0 15px 0 ;
		}
		&.form-row-half {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.form-control {
				width: 49%;
			}
		}
	}
	.form-control {
		position: relative;
		label {
			display: none;
		}

		.wpcf7-form-control-wrap {
			display: block;
		}
		.wpcf7-submit,.wpcf7-tmp-submit {
			width: 100%;
			display: block;
			border-radius: 5px;
			background: $color_2;
			color: $white;
			font-family: $font ;
			font-size: 17px;
			font-weight: 700;
			text-transform: uppercase;
			padding: 16px 15px ;
			text-align: center;
			border: none ;
			cursor: pointer ;
			transition: all .3s ease-in-out;
			&:hover {
				background: darken($color_2, 5%);
			}
		}
		&-file {
			position: relative;
			.wpcf7-file {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				width: 100%;
				height: 100%;
			}
			.wpcf7-form-control-wrap {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				opacity: 0;
				width: 211px;
				height: 100%;
				cursor: pointer;
				border-radius: 40px;
			}
			&-btn {
				pointer-events: none;
				width: 211px;
				height: 53px;
				box-shadow: inset 0 -2px 0 #5d798a;
				border-radius: 40px;
				background: #FFF no-repeat url(../img/icon-upload.svg) center right 20px;
				color: #272731;
				font-size: 18px;
				font-weight: 300;
				display: block;
				padding: 15px 45px 15px 20px;
				cursor: pointer;
				text-align: left;
				word-wrap: break-word;
				white-space: nowrap;
				text-overflow: ellipsis;
				direction: rtl;
				position: relative;
				overflow: hidden;
			}
		}
		&-btn {
			position: relative;

			
		}

		&-label-spec-1 .wpcf7-list-item-label {
			text-decoration: underline;
			font-size: 13px;
			line-height: 22px;

			a {
				color: #FFF;
			}
		}
	}
	.ajax-loader {
		display: none !important;
	}
	input:not([type="submit"]),
	select,
	textarea {
		width: 100%;
		border-radius: 3px;
		border: 1px solid #cacaca;
		background-color: $white;
		color: #666666;
		font-family: $font ;
		font-size: 15px;
		padding: 12px 17px ;
		appearance: none ;
		outline: none ;
		&::-webkit-input-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder,
		&:-moz-placeholder {
			color: #666666;
			font-family: $font ;
			font-size: 15px;
			opacity: 1 ;
		}

	}
	.label-file {
		display: flex !important;
		input {
			display: none;
		}
		span {
			transition: all .3s ease-in-out ;
			&:hover {
				opacity: 0.7 ;
			}
		}
		.your-file {
			position: absolute;
			width: 100%;
		}
		.select-file-span-1 {
			display: flex;
			width: 100px;
			min-width: 100px;
			height: 43px;
			border-radius: 3px 0 0 3px;
			padding: 0 0 0 35px;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-family: $font ;
			font-size: 15px;
			font-weight: 300;
			background: gray url('../img/upload-file.svg') no-repeat left 16px center;
			cursor: pointer;
		}
		.select-file-span-2 {
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			height: 43px;
			border-radius: 0 3px 3px 0 ;
			border-top: 1px solid #cacaca;
			border-right: 1px solid #cacaca;
			border-bottom: 1px solid #cacaca;
			background-color: #fff;
			width: 100%;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			color: #000;
			font-family: $font ;
			font-size: 15px;
			font-weight: 300;
			padding: 0 0 0 16px;
			cursor: pointer;

		}
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance:textfield; 
	}

	select {
		color: #666666;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 0;
		text-overflow: '';
		padding-right: 25px;
		cursor: pointer;
		background-image: url('../img/arrow_down.svg');
		background-repeat: no-repeat;
		background-position: right 15px center;
		background-size: 10px auto ;
		@include media-max(767px) {
			background-size: 12px;
		}
		&::-ms-expand {
			display: none;
		}
		option {
			color: #666666;
			font-family: $font;
			font-size: 15px;
			opacity: 1 ;
			padding-left: 0;
			padding-right: 0;
			font-weight: 400;
		}
	}

	textarea {
		height: 140px;
		resize: none ;
	}

	.wpcf7-not-valid-tip {
		position: absolute;
		top: 0;
		font-size: 12px;
		text-align: right;
		width: 100%;
		pointer-events: none;
		padding: 5px 10px;
	}

	form {
		position: relative;
	}

	.wpcf7-list-item {
		margin: 0;
		input[type="checkbox"] {
			display: none;

			&:checked ~ .checkbox-icon {
				background-image: url(../img/icon-checked.svg);
			}
		}

		.checkbox-icon {
			width: 25px;
			height: 25px;
			border-radius: 3px;
			border: 1px solid #d0d0d0;
			background: no-repeat #ffffff center;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
		}

		.wpcf7-list-item-label {
			margin-left: 12px;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			width: calc(100% - 38px);
			color: rgba(0, 0, 0, 0.7);
		}
	}
	div.wpcf7-response-output {
		border: 0;
		margin-top: 0;
		padding: 5px 10px;
		font-weight: 400;
		&.wpcf7-validation-errors,
		&.wpcf7-acceptance-missing {
			color: #F00;
		}
		&.wpcf7-mail-sent-ok {
			color: #8bc441;
		}
	}
}
body .s-contact .btn-submit {
	@media (max-width: 1023px) {
		padding: 13px 25px;
		font-size: 15px;
	}
}


@media only screen and (max-width: 767px) {
	div.wpcf7 {
		margin: 5px 0 30px 0 ;
		.wpcf7-form-row {
			&.form-row-half {
				.form-control {
					width: 100%;
				}
			}
		}
	}
}