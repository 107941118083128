

$font-size-base: 14px;


$black: #000000 ;
$white: #ffffff ;
$grey: #f2f2f2;
$orange: #e85c00;
$green: #009901;


$main_color: $orange;
$color_2: $green;

$font-lobster: 'Lobster', cursive ;
$font: Helvetica, sans-serif ;