.top-bar-logos {
	position: relative;
	z-index: 101;
	margin-bottom: 0 !important;
	.top-bar__row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 10px;
		padding-bottom: 10px;
		img {
			max-height: 40px ;
			max-width: 250px ;
		}
	}
}

/*-=Medium screens=-*/
@media only screen and (max-width: 767px) {
	.top-bar-logos {
		display: none;
	}
}

