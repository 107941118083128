.headline {
    // font-family: $ffFuturaLT-Book;
    margin: 0 0 25px 0;
    display: block;
    color: #0a2022;

    &--1 {
        font-size: 50px;
        font-weight: 700;
        line-height: 47px;

        
        &.font-weight-light {
        }
    }
    &--2 {
        font-size: 35px;
        font-weight: 700;
        line-height: 35px;
    }
    &--3 {
        // font-family: $ffFuturaLT-Book;
        font-size: 26px;
        font-weight: 700;
        line-height: 26px;
    }
    &--4 {
        // font-family: $ffFuturaLT-Book;
        font-size: 22px;
        font-weight: 700;
        line-height: 22px;
    }
    &--5 {
        // font-family: $ffFuturaLT-Book;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
    }
    &--6 {
        // font-family: $ffFuturaLT-Book;
        font-size: 17px;
        font-weight: 700;
        line-height: 22px;
    }
    &--7 {
        // font-family: $ffFuturaLT-Book;
        font-size: 80px;
        font-weight: 700;
        line-height: 95px;
    }
}

.paragraph {
    display: inline-block;
    margin: 0 0 25px 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    &--2 {
        font-size: 20px;
        line-height: 30px;
    }

    &--3 {
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
    }
}