
.top-page-sectoin {
	padding: 220px 0 ;
	@extend %bgcover ;
	.top-page-sectoin-row {
		max-width: 875px ;
		margin: 0 auto 0 ;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
	}
	.page-title {
		color: $white ;
		font-size: 55px;
		line-height: 66px;
		font-weight: 700;
		margin: 0 0 0 0 ;
	}
}


/*-=Medium screens=-*/
@media only screen and (max-width: 767px) {
	.top-page-sectoin {
		padding: 110px 0 135px 0 ;
		.top-page-sectoin-row {
			padding-left: 20px;
			padding-right: 20px;
		}
		.page-title {
			font-size: 35px;
			line-height: 43px;
		}
	}
}



@media only screen and (min-width: 641px) and (max-width: 1024px) {
	/* min-width 641px and max-width 1024px, use when QAing tablet-only issues */
}
@media only screen and (max-width: 500px) {
	
}
/*-=Large screens=-*/
@media only screen and (min-width: 1025px) {
	/*min-width 1025px, large screens*/
}

@media only screen and (min-width: 1201px) {
	/* min-width 1201px, large screens*/
}

@media only screen and (min-width: 1201px) and (max-width: 1280px) {
	/* min-width 1201px and max-width 1280px, use when QAing MacBook issues */
}