.custom-button {
	display: inline-block;
	text-align: center;
	padding: 10px 25px ;
	font-size: 17px;
	&.white {
		background: $white ;
		color: $main_color ;
		&:hover {
			background: $main_color ;
			color: $white ;
		}
		&.right-arrow {
			position: relative;
			&:after {
				width: 21px;
				height: 21px;
				position: absolute;
				content: '' ;
				background: url('../img/arrow_right_orange.svg') no-repeat center ;
				background-size: 100% auto ;
				top: 0;
				bottom: 0;
				margin: auto ;
				right: 19px;
			}
		}
	}
	&.green {
		background: $color_2 ;
		color: $white;
		&:hover {
			background: $white ;
			color: $main_color ;
		}
	}
	&.green--2 {
		background: $color_2 ;
		color: $white;
		&:hover {
			background: #027903 ;
			color: $white ;
		}
	}
}