.contact-page-section {
	padding: 90px 0 150px 0 ;
}
.contact-page-title {
	display: block;
	color: $black;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 45px ;
}
.contact-info-row {
	display: flex;
	flex-wrap: wrap;
	padding-top: 65px;
	margin-bottom: 60px;
	max-width: 890px ;
	.contact-info-column {
		width: 33.3333%;
		.contact-image-block {
			height: 65px ;
			margin: 0 0 20px 0 ;
			&.image-block-address {
				background: url('../img/marker-orange.svg') no-repeat center ;
			}
			&.image-block-phone {
				background: url('../img/phone-orange.svg') no-repeat center ;
			}
			&.image-block-email {
				background: url('../img/mail-orange.svg') no-repeat center ;
			}
		}
		h4 {
			font-size: 25px;
			margin: 0 0 10px 0 ;
		}
		p,a {
			color: #a0a0a0;
			font-size: 16px;
			line-height: 29px;
			font-weight: 400;
			box-shadow: none ;
			margin: 0 0 0 0 ;
		}
		a {
			&:hover {
				color: $main_color ;
			}
		}
	}
}
.people-row-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 85px 0 ;
	.column {
		width: 33.3333% ;
		display: flex;
		margin: 20px 0 5px 0;
		.people-photo {
			width: 163px;
			height: 163px;
			@extend %bgcover ;
			min-width: 163px ;
		}
		.people-row-list-info {
			padding: 30px 0 10px 25px ;
			span {
				display: block;
			}
			.name {
				color: $black;
				font-size: 20px;
				text-transform: uppercase;
				margin: 0 0 10px 0 ;
			}
			.position {
				color: $main_color;
				font-size: 13px;
				font-weight: 300;
				text-transform: uppercase;
				margin: 0 0 15px 0 ;
			}
			p,a {
				color: $black;
				font-size: 13px;
				font-weight: 300;
				box-shadow: none ;
				line-height: 20px ;
				margin: 0 0 0 0 ;
			}
			a {
				&:hover {
					color: $main_color ;
				}
			}
		}

	}
}
.map-section {
	iframe {
		width: 100%;
		height: 365px;
	}
}



@media only screen and (max-width: 1200px) {
	.people-row-list {
		.column {
			width: 50%;
		}
	}
}
@media only screen and (max-width: 991px) {
	.people-row-list {
		.column {
			width: 100%;
		}
	}
}
@media only screen and (max-width: 767px) {
	.contact-page-section {
		padding: 45px 0 0px 0 ;
	}
	.contact-page-title {
		font-size: 30px;
		margin-bottom: 25px;
	}
	.map-section {
		iframe {
			height: 90px;
		}
	}
	.contact-info-row {
		padding-left: 25px;
		padding-right: 25px;
		margin-bottom: 0px;
		.column	{
			width: 50%;
			padding-left: 0px;
			padding-right: 0px;
			margin-bottom: 45px;
		}
		.contact-info-column {
			h4 {
				font-size: 20px;
			}
			p,a {
				font-size: 15px;
				line-height: 24px;
			}
		}
	}
	.people-row-list {
		margin: 0 0 100px 0 ;
		.column {
			margin: 0 0 13px 0 ;
			.people-photo {
				width: 136px;
				height: 136px;
				min-width: 136px ;
			}
			.people-row-list-info {
				padding: 25px 0 10px 20px ;
				.name {
					font-size: 18px;
					margin: 0 0 7px 0 ;
				}
				.position {
					font-size: 12px;
					margin: 0 0 10px 0 ;
				}
				p,a {
					font-size: 12px;
				}
			}
		}
	}
}
@media only screen and (min-width: 641px) and (max-width: 1024px) {

	
}


