.footer {
	background-image: url('../img/footer-bg.jpg') ;
	@extend %bgcover ;
	padding: 20px 0 40px 0 ;
	position: relative;
}

.footer-row {
	// max-width: 875px;
	margin: 0 auto 0;
}
.footer-row-copyright {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 21px;
}
.footer-column-title{
	color: #ffffff;
	font-size: 25px;
	line-height: 26.4px;
	margin-bottom: 13px;
}
.footer-logo-column  {
	display: flex;
	align-items: center;
	margin-left: auto;
	position: absolute;
	right: 0;
	a{
		flex: 1;
	}
	img{
		max-height: 70px;
	}
}
.copyright {
	color: $white;
	font-size: 16px;
	margin: 0 0 0 0 ;
	a {
		color: $white ;
		text-decoration: none;
		box-shadow: none ;
		&:hover {
			color: $main_color ;
			box-shadow: inset 0 -1px 0 $main_color ;
		}
	}
}
.footer-title {
	color: $white;
	font-family: $font-lobster ;
	font-weight: 400;
	font-size: 48px;
	line-height: 56px;
	display: block;
	margin: 0 0 35px 0 ;
	text-align: center;
}
.footer-columns-row {
	display: flex;
	align-items: center;
	justify-content: center;
	.footer-column {
		> span {
			display: block;
			color: $white;
			font-size: 16px;
			line-height: 26.4px;
			padding: 0 0 0 25px ;
			background-repeat: no-repeat ;
			background-position: left 8px ;
			background-size: 12px;
			&.footer-address {
				background-image: url('../img/marker.svg');
			}
			&.footer-phone {
				background-image: url('../img/phone.svg');
			}
			&.footer-mail {
				background-image: url('../img/email.svg');
				background-position: left 9px 
			}
			&.footer-site-link {
				background-image: url('../img/internet.svg');
			}
			.footer-contact-label {
				display: inline-block;
			}
			a {
				color: $white;
				font-size: 16px;
				line-height: 26.4px;
				&:hover {
					color: $main_color ;
				}
			}
		}
	}
}
.footer-menu-column {
	.menu {
		list-style: none ;
		margin: 0 0 0 0 ;
		padding: 0 0 0 0 ;
		li {
			a {
				color: $white;
				font-size: 16px;
				line-height: 26.4px;
				&:hover {
					color: $main_color ;
				}
			}
		}
	}
}
@media only screen and (max-width: 1260px) {
	.footer-logo-column{
		position: relative;
		right: unset;
	}
}
@media only screen and (min-width: 992px) {
	.footer-row {
		// max-width: 720px;
	}
}

@media only screen and (min-width: 768px) {
	.footer-columns-row {
		.footer-column {
			&.footer-menu-column {
				padding-right: 90px;
			}
			&.footer-contact-column {
				padding-right: 30px;
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	.footer {
		padding: 65px 0 ;
	}
	.footer-title {
		font-size: 30px;
		line-height: 30px ;
	}
	.footer-menu-column {
		.menu {
			li {
				a {
					font-size: 15px;
					line-height: 25.4px;
				}
			}
		}
	}
	.footer-row-copyright {
		display: flex;
		flex-wrap: wrap;
		.column {
			width: 100%;
			&.text-center {
				text-align: left !important ;
			}
		}
	}
	.footer-columns-row {
		flex-wrap: wrap;
		.footer-column {
			> span {
				font-size: 15px;
				line-height: 18px;
			}
			&.footer-menu-column {
				// width: 50%;
				order: 1 ;
			}
			&.footer-contact-column {
				order: 3 ;
				margin: 15px 0 35px 0 ;
				width: 50%;
			}
			&.footer-logo-column {
				order: 2 ;
				width: 50%;
				text-align: left;
				display: flex;
				margin-left: 0;
			}

		}
		.footer-logo-column  {
			img {
				max-width: 100px ;
				width: auto;
			}
		}
	}
	.copyright {
		font-size: 13px;
		line-height: 24px;
	}
}



@media only screen and (min-width: 641px) and (max-width: 1024px) {

}
@media only screen and (max-width: 500px) {
	.footer-column {
		&.footer-contact-column {
			width: 100%!important;
		}
		&.footer-logo-column {
			width: 100%!important;
		}
		
	}
}