//use @extend %bgcover; to selector if you need background cover;
%bgcover {
	background-size:cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

//use @extend %bgcontain; to selector if you need contain image in block;
%bgcontain {
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}