.product-search-box {
    &__input {
        width: 185px;
        border: none;
        background: transparent;
        border-bottom: 1px solid #fff;
        font-size: 16px;
        color: #fff;
        padding: 3px 3px 3px 25px;
        background-image: url(../img/magnifier.svg);
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 19px auto;
        &::placeholder{
            opacity: 1;
            color: white!important;
        }
    }
}

@media (max-width: 767px) {
    .column--product-search-box {
        display: none;
    }
}