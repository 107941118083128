
.thank-you-page-section {
	padding: 130px 0 100px 0 ;
	img {
		max-height: 100px ;
		margin: 0 0 34px 0 ;
	}
	h2 {
		color: $black;
		font-size: 40px;
		font-weight: 700;
		line-height: 50px;
		margin: 0 0 21px 0 ;
	}
	p {
		color: #a0a0a0;
		font-size: 16px;
		line-height: 29px;
	}
}

@media only screen and (max-width: 767px) {
	.thank-you-page-section {
		padding: 70px 0 75px 0 ;
		img {
			max-height: 50px ;
			margin: 0 0 23px 0 ;
		}
		h2 {
			font-size: 30px;
			line-height: 36px;
			margin: 0 0 23px 0 ;
		}
		p {
			font-size: 15px;
			line-height: 22px;
		}
	}
}

