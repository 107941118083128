.text-section {
	padding: 100px 0 ;
	p {
		margin: 0 0 65px 0 ;
	}
}
.default-template-row {
	max-width: 875px ;
	margin: 0 auto 0 ;
	padding-left: 15px;
	padding-right: 15px;
	text-align: justify;
}

.wp-block-image {
	margin: 0 0 60px 0 ;
	width: 100%;
	font-size: 0;
	img {
		width: 100%;
		border-radius: 4px;
	}
}
.wp-block-columns {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0px 0 60px 0 ;
	p:empty {
		display: none;
	}
	.wp-block-cover {
		@extend %bgcover ;
		border-radius: 4px ;
		height: 100%;
		position: relative;
		.wp-block-cover__inner-container {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			color: $white ;
		}
	}
	&.has-4-columns {
		.wp-block-column {
			width: 24%;
		}
		.wp-block-cover {
			padding: 91% 0 0 0 ;
		}
	}
	&.has-2-columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.wp-block-column {
			width: 49.5%;
		}
		.wp-block-cover {
			padding: 74% 0 0 0 ;
		}
		&.two-third {
			.wp-block-cover {
				padding: 73% 0 0 0 ;
			}
			.wp-block-column {
				&:first-of-type {
					width: 59.5%;
				}
				&:last-of-type {
					width: 39.5%;
				}
			}
		}	
	}
}
@media only screen and (max-width: 767px) {
	.default-template-row {
		padding-left: 25px;
		padding-right: 25px;
	}
	.text-section {
		padding: 50px 0 ;
		p {
			margin: 0 0 50px 0 ;
		}
	}
	.wp-block-image {
		margin: 0 0 50px 0 ;
	}
}



@media only screen and (min-width: 641px) and (max-width: 1024px) {

}
@media only screen and (max-width: 500px) {
	
}