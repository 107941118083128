.default {
    color: #0a2022;

    h1, h2, h3, h4, h5, h6 {
        display: block;
        @extend .headline;
    }

    h1 {
        @extend .headline--1;
    }

    h2 {
        @extend .headline--2;
    }

    h3 {
        @extend .headline--3;
    }

    h4 {
        @extend .headline--4;
    }

    h5 {
        @extend .headline--5;
    }

    h6 {
        @extend .headline--6
    }

    p {
        display: block;
        @extend .paragraph;
    }
    a {
        color: $orange;
        text-decoration: underline;
    }

    ul {
        display: block;
        margin: 0 0 35px 0;
        padding-top: 20px;
        list-style: none;
        padding-left: 16px;

        @include media-max(767px) {
            margin-bottom: 24px;
            padding-left: 15px;
            padding-top: 0;
        }
        li {
            position: relative;
            &:before {
                content: "•";
                padding-right: 19px;
                padding-left: 19px;
            }
        }
    }
}