.pre-footer {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 0 0 ;
	.footer-gallery-item {
		height: 14.8vw ;
		@extend %bgcover ;
		width: 20%;
	}
}

@media only screen and (max-width: 767px) {
	.pre-footer {
		.footer-gallery-item {
			height: 25vw ;
			width: 33.33333%;
			&:nth-child(4),
			&:nth-child(5) {
				display: none;
			}
		}
	}
}

@media only screen and (min-width: 641px) and (max-width: 1024px) {

}
@media only screen and (max-width: 500px) {

}