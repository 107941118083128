
.archive-product {
	padding: 75px 0 ;
	.woocommerce-breadcrumb {
		margin: 0 0 50px 0 
	}
	.woocommerce-products-header__title {
		color: $main_color;
		font-size: 50px;
		font-weight: 500;
		line-height: 56px;
		text-transform: uppercase;
		margin: 0 0 20px 0 ;
	}
}
.woocommerce {

	.term-description {
		&__read-more {
			font-weight: 700;
			cursor: pointer;
		}
		&__full {
			display: none;
		}
		&__collapse {
			display: inline-block;
			font-weight: 700;
			cursor: pointer;
			color: #000;
			font-size: 17px;
			line-height: 30px;
			margin-bottom: 40px;
		}
	}

	.archive-product-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		.woocommerce-notices-wrapper,
		.woocommerce-result-count,
		.woocommerce-ordering{
			display: none !important;
		}
		ul.products.columns-3 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 74%;
			&:before {
				display: none !important;
			}
			&:after {
				width: 32%;
			}
			li.product {
				display: flex;
				flex-direction: column;
				float: none ;
				width: 32%;
				box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
				background-color: $white;
				padding: 20px 14px 17px 14px ;
				margin: 0 0 24px 0 ;
				.buttons-row {
					margin-top: auto !important;
				}
				.product-category {
					display: block;
					color: #999999;
					font-size: 15px;
					margin: 0 0 4px 0 ;
					white-space: nowrap;
					overflow: hidden;
				}
				.woocommerce-loop-product__link {
					display: flex;
					flex-wrap: wrap;

					.onsale {
						display: none !important;
					}

					.woocommerce-loop-product__title {
						order: 1 ;
						color: $black;
						font-family: $font ;
						font-size: 18px;
						font-weight: 500;
						line-height: 22px;
						margin: 0 0 25px 0 ;
						padding: 0 0 0 0 ;
						overflow: hidden;
						width: 100%;
						height: 44px;
					}
					img {
						order: 2 ;
						width: 100%;
						margin: 0 0 26px 0 ;
						height: 260px;
						object-fit: contain;
					}
					.description {
						order: 3 ;
						p {
							color: #999999;
							font-size: 14px;
							line-height: 19px;
							margin: 0 0 10px 0 ;
						}
					}
				}
				.row {
					margin: 0 0 0 0 ;
					justify-content: space-between;
					align-items: center ;
					a {
						color: $color_2;
						font-size: 16px;
						font-weight: 500 ;
						&:hover {
							color: $main_color ;
						}
					}
					.col-6 {
						width: auto;
						flex: unset ;
						padding: 0 0 0 0 ;
						max-width: none ;
					}
					.text-right {
						a {
							text-align: center;
							color: $white;
							font-size: 12px;
							font-weight: 700;
							text-transform: uppercase;
							display: inline-block;
							border-radius: 3px;
							background-color: $main_color;
							padding: 9px 10px ;
							border: 2px solid $main_color ;
							&:hover {
								background: $white ;
								color: $main_color ;
							}
						}
						.price {
							color: $black;
							font-family: $font ;
							font-size: 18px;
							font-weight: 500;
							line-height: 22px;
							margin: 0 0 0 0 ;
						}
					}
				}
			}
		}
		.category-sidebar {
			width: 24.1%;
			.category-sidebar-container {
				
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	
}
@media only screen and (min-width: 992px) {
	.archive-product {
		.load-more-row {
			padding: 50px 0 0 26%;
			width: 100%;
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.archive-product {
		.load-more-row {
			padding: 50px 0 0 31%;
			width: 100%;
		}
	}
}
@media only screen and (max-width: 991px) {
	.woocommerce {
		.archive-product-row {
			.category-sidebar {
				width: 30%;
			}
			ul.products.columns-3 {
				width: 69%;
				li.product {
					width: 49%;
				}
			}
		}

	}
}

@media only screen and (max-width: 767px) {
	.woocommerce {
		.archive-product-row {
			.category-sidebar {
				width: 100%;
				margin: 0 0 25px 0 ;
				padding: 0 25px ;
			}
			ul.products.columns-3 {
				width: 100%;
				li.product {
					box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
					background-color: $white;
					margin: 0 0 25px 0 ;
					padding: 25px 25px 30px 25px ;
					.woocommerce-loop-product__link .description {
						p {
							margin: 0 0 20px 0 ;
						}
					}
					.row {
						.text-right {
							a {
								padding: 9px 34px;
							}
						}
					}
				}
			}
		}
	}
	.archive-product {
		padding: 30px 0 75px 0  ;
		.woocommerce-products-header__title {
			font-size: 30px;
			font-weight: 500;
			line-height: 35px;
			text-transform: none;
		}
	}
	.archive-product-row {
		padding-left: 0;
		padding-right: 0;
	}
}
@media only screen and (max-width: 640px) {
	.woocommerce {
		.archive-product-row {
			ul.products.columns-3 {
				li.product {
					width: 100%;
				}
			}
		}
	}
}
@media only screen and (min-width: 641px) and (max-width: 1024px) {

	
}


