.single.single-product .woocommerce-product-gallery__image.flex-active-slide {
	min-height: 440px;
	text-align: center;
}
.single.single-product .woocommerce-product-gallery__image a {
	min-height: 440px;
	display: flex;
	align-items: center ;
	justify-content: center;
}

.single.single-product .woocommerce-product-gallery__image a img ,
.single.single-product .woocommerce-product-gallery__image.flex-active-slide a img {
	max-height: 440px;
	/*width: auto !important;*/
	width: 100% !important;
}

.single-product-section {
	padding: 70px 0 0 0 ;
	.woocommerce-notices-wrapper {
		display: none;
	}
	.product_title {
		color: $main_color;
		font-family: $font ;
		font-size: 50px;
		font-weight: 500;
		line-height: 56px;
		text-transform: uppercase;
		margin: 0 0 20px 0 ;
		position: relative;

		&::after {
			content: '';
			max-width: 70%;
			width: 485px;
			height: 1px;
			background-color: #eeeeee;
			display: block;
			margin-top: 15px;
		}
	}

	.woocommerce-breadcrumb {
		margin: 0 auto 0 ;
		max-width: 1125px ;
	}

	.single-product-info-row {
		max-width: 1125px;
		display: flex;
		flex-wrap: wrap;
		div.single-product-info-column.summary {
			width: 50%;
			display: flex;
			justify-content: flex-end;

			@media only screen and (max-width: 767px) {
				justify-content: center;
			}
		}
		.single-product-gallery-column {
			width: 50%;
			position: relative;

			.onsale {
				display: none !important;
			}
			
			.woocommerce-product-gallery {
				.woocommerce-product-gallery__trigger {
					top: 15px ;
					left: 15px ;
					right: auto ;
					display: none;
				}
				.flex-viewport {
					width: 100%;
					border: 3px solid #e85c0091;
				}
				.flex-control-thumbs {
					display: flex;
					flex-wrap: wrap;
					padding: 0 0 0 0 ;
					margin: 15px -1.5% 0 -1.5% ;
					li {
						display: inline-block;
						vertical-align: top;
						float: none ;
						width: 17% ;
						margin: 0 1.5% 13px 1.5% ;
						img {
							opacity: 0.6;
							transition: opacity .3s ease-in-out ;
							border: 3px solid $main_color ;
							&:hover {
								opacity: 1 ;
							}
							&.flex-active {
								opacity: 1 ;
							}

						}
					}
				}
			}
			div.images {
				width: 100%;
			}
		}
	}

	.product-right {
		max-width: 480px;
		width: 100%;
	}

	form.cart, form.cart2 {
		max-width: 480px ;
		width: 100%;
		/*padding: 22px 0 0 0 ;*/
		padding: 6px 30px 0 0 ;
		margin: 0 0 130px 0 ;
		.label {
			label {
				color: $black;
				font-family: $font ;
				font-size: 17px;
				font-weight: 500 !important;
				display: block;
				margin: 0 0 13px 0 ;
			}
		}
		.value {
			select {
				border-radius: 3px;
				border: 1px solid #cacaca;
				background-color: $white;
				background-image: url('../img/select-arr.jpg');
				background-repeat: no-repeat;
				background-position: right 15px center ;
				color: $black;
				font-family: $font ;
				font-size: 17px;
				font-weight: 300;
				width: 100%;
				appearance: none ;
				padding: 11px 16px ;
				margin: 0 0 15px 0 ;
			}
		}

		.quantity-row {
			display: flex;
		}
		.select-file {
			display: flex;
			.select-file-label {
				display: flex;
				width: 100%;
				margin: 0 0 26px 0 ;
			}
			span {
				transition: all .3s ease-in-out ;
				&:hover {
					opacity: 0.7 ;
				}
			}
			.file-btn {
				display: flex;
				width: 100px;
				min-width: 100px ;
				height: 43px;
				border-radius: 3px 0 0 3px;
				padding: 0 0 0 35px;
				align-items: center;
				justify-content: center ;
				color: $white;
				font-family:  $font ;
				font-size: 15px;
				font-weight: 300;
				background: #808080 url('../img/upload-file.svg') no-repeat left 16px center ;
				cursor: pointer ;
			}
			.file-text {
				display: flex;
				height: 43px;
				border-radius: 0 3px 3px 0 ;
				border-top: 1px solid #cacaca;
				border-right: 1px solid #cacaca;
				border-bottom: 1px solid #cacaca;
				background-color: $white;
				width: 100%;
				align-items: center;
				color: $black;
				font-family:  $font ;
				font-size: 15px;
				font-weight: 300;
				padding: 0 0 0 16px ;
				cursor: pointer ;
			}
		}
		.add-comment-to-order {
			display: flex;
			flex-wrap: wrap;
			margin: 0 0 38px 0 ;
			a {
				color: $black;
				font-family: $font ;
				font-size: 19px;
				font-weight: 500;
				cursor: pointer ;
				&:hover {
					color: $main_color ;
				}
			}
			textarea {
				margin: 15px 0 0 0 ;
				width: 100%;
				border-radius: 3px;
				border: 1px solid #cacaca;
				background-color: $white;
				color: #666666;
				font-family: $font ;
				font-size: 15px;
				padding: 12px 17px ;
				appearance: none ;
				height: 120px;
				resize: none ;
				outline: none ;
				&::-webkit-input-placeholder,
				&::-moz-placeholder,
				&:-ms-input-placeholder,
				&:-moz-placeholder {
					color: #666666;
					font-family: $font ;
					font-size: 15px;
					opacity: 1 ;
				}
			}
		}
		.single-product-attr_pa_leveranstid {
			margin: 0 0 45px 0 ;
			.value {
				label {
					display: flex;
					width: 100%;
					justify-content: space-between ;
					margin: 0 0 12px 0 ;
					input {
						display: none ;

					}
					input:checked ~ .single-product-attr-label {
						&:before {
							background: $color_2 ;
						}
					}
					.single-product-attr-label {
						padding: 0 10px 0 32px ;
						position: relative;
						display: inline-block;
						color: $black;
						font-family: $font ;
						font-size: 17px;
						font-weight: 300;
						&:before {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							margin: auto ;
							content: '' ;
							width: 20px;
							height: 20px;
							border-radius: 11px;
							border: 4px solid $white ;
							box-shadow: 0 0 0 1px #cacaca;
							background-color: $white;
						}
					}
					.single-product-attr-price {
						color: $black;
						font-family: $font ;
						font-size: 17px;
						font-weight: 500;
					}
				}
			}
		}

		.single_variation_wrap {
			.single-product-button-row {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.button-row-column {
					width: 48% ;
				}
			}

			.single_add_to_cart_button.button {
				border-radius: 5px;
				background-color: $color_2;
				color: $white;
				font-family: $font;
				width: 100%;
				width: 48%;
				display: flex ;
				justify-content: center;
				text-align: center ;
				height: 57px;
				font-size: 17px;
				font-weight: 700;
				float:none ;
				text-transform: uppercase;
				padding: 18px 10px ;
				transition: all .3s ease-in-out ;
				&:hover {
					background: darken($color_2, 10%);
				}
			}
			.modal-offer-mini-button {
				width: 100%;
				border-radius: 5px;
				background: $main_color;
				color: $white;
				font-family: $font;
				font-size: 17px;
				float:none ;
				text-align: center ;
				display: flex ;
				height: 100%;
				justify-content: center;
				font-weight: 700;
				text-transform: uppercase;
				padding: 18px 10px ;
				&:hover {
					background: darken($main_color, 10%);
				}
			}
		}
	}
}
.woocommerce, .archive-product {
	.woocommerce-breadcrumb {
		margin: 0 auto 35px ;
		color: $black;
		font-family: $font ;
		font-size: 17px;
		a {
			color: $black;
			font-family: $font ;
			font-size: 17px;

			&:hover {
				color: $main_color ;
			}
		}
		span {
			display: inline-block;
			margin: 0 8px 0 8px ;
		}
	}
}

.archive-product {
	.woocommerce-breadcrumb {
		max-width: 1250px;
	}
}

.single-product-tab-section {
	.tab-box-list {
		border-top: 1px solid $main_color;
		padding: 80px 0 ;
		.two-column-row {
			display: flex;
			flex-wrap: wrap;
			&.two-column-row {
				.column {
					width: 50%;
				}
			}
		}
		p {
			color: #1f1f1f;
			font-size: 16px;
			line-height: 26px;
			margin: 0 0 20px 0 ;
		}
	}
	.tab-list {
		display: flex;
		padding: 0 0 0 0 ;
		margin: 0 0 -1px 0 ;
		list-style: none ;
		width: 100%;
		.tab-item {
			& + .tab-item {
				margin: 0 0 0 4px ;
				@media only screen and (max-width: 767px) {
					margin: 4px 0 0 0 ;
				}
			}
			a {
				display: block;
				font-size: 22px;
				font-weight: 500;
				padding: 15px 105px 10px 105px ;
				background: $main_color ;
				color: $white ;
				border-radius: 9px 9px 0 0;
				border-top: 1px solid $main_color ;
				border-left: 1px solid $main_color ;
				border-right: 1px solid $main_color ;
				text-align: center;
				&:hover {
					background: #FAFAFA ;
					color: #050505;
				}
			}
			&--active {
				a {

					background: #FAFAFA ;
					color: #050505;
				}
			}
		}
	}

}
.js-single-product-attr {
	position: relative;
	.js-attr-status {
		position: absolute;
		right: -30px;
		top: 45px;
	}
}
.woocommerce div.product div.summary {
	margin-bottom: 0;
}
.woocommerce div.product form.cart,
.woocommerce div.product form.cart2 {
	margin: 0 0 130px 0 ;
}
.quantity-title {
	color: $black;
	font-size: 17px;
	font-weight: 500;
	display: block;
	margin: 0 0 9px 0 ;
}
.quantity-row {
	display: flex;
	justify-content: space-between;
	align-items: center ;
	border-radius: 3px;
	border: 1px solid #cacaca;
	background-color: $white;
	padding: 5px 5px ;
	margin: 0 0 22px 0 ;
	.single-product-quantity {
		color: $black;
		font-size: 17px;
		line-height: 23px;
		font-weight: 700;
		width: 45px;
		text-align: center;
		cursor: pointer ;
	}
	input {
		width: 155px;
		height: 31px;
		border-radius: 4px;
		background-color: #fafafa;
		border: none ;
		text-align: center;
		color: $black;
		font-family: $font ;
		font-size: 17px;
		font-weight: 700;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance:textfield;
	}
}

.single-product-total {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-top: 1px solid #eeeeee;
	padding: 45px 0 30px 0 ;
	.label-total {
		color: $black;
		font-family: $font ;
		font-size: 25px;
		font-weight: 500 !important;
	}
	.label-total-exklmoms {
		color: $black;
		font-family: $font ;
		font-size: 17px;
		font-weight: 300 !important;
		line-height: 30px;
		display: block;
	}
	.js-product-price {
		color: $black;
		font-family: $font ;
		font-size: 25px;
		font-weight: 500;
		display: block;
		white-space: nowrap;
	}
	.single-product-price-per-piece {
		width: 100%;
		text-align: right;

		.js-product-price-per-piece {
			span.price {
				font-size: unset;
				color: unset;
			}
		}
	}
}
.modal-offer {
	display: block !important;
	position: fixed;
	top: 0;
	right: 0;
	background: #f9f9f9;
	z-index: 100;
	height: 100vh ;
	width: 540px;
	transition: all .5s ease-in-out;
	z-index: 9999;
	&.d-none {
		right: -540px;
	}
	h3 {
		color: $main_color;
		font-size: 50px;
		line-height: 56px ;
		font-weight: 500;
		line-height: 35px;
		text-align: center;
		margin: 0 0 35px 0 ;
	}
	.modal-offert-text {
		p {
			color: #1f1f1f;
			font-size: 16px;
			line-height: 26px;
			margin: 0 0 30px 0 ;
		}
	}
	&__btn {

		transform: rotate(-90deg) translateX(-40px) ;
		top: 50%;
		transform-origin: bottom left ;
		align-items: center ;
		justify-content: center ;
		position: absolute;
		left: 0px;
		width: 220px;
		height: 70px;
		background: $main_color;
		display: flex ;
		color: $white ;
		font-family: $font ;
		font-size: 17px;
		font-weight: 700;
		text-transform: uppercase;
		border-radius: 5px  5px 0 0 ;
		&:hover {
			background: darken($main_color, 7%);
		}
	}
	&__hide {
		padding: 75px 40px 40px 40px;
		max-height: 100vh;
		overflow: auto;
	}
	.modal-close {
		position: absolute;
		top: 12px;
		right: 25px ;
		width: 34px;
		height: 34px;
		background: url('../img/close-popup-thank-you.svg');
	}
}
.modal-thank-you {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 999999;
	background: rgba($black, .8);
	padding: 0;
	.modal-thank-you-main-container {
		position: absolute ;
		width: 900px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 20px 20px ;
		max-height: 100% ;
		max-width: 100% ;
	}
	.modal-thank-you-container {
		width: 100% ;
		overflow: auto;
		max-height: calc(100vh - 40px);
		box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		border: 1px solid #c0c1c6;
		background-color: $white;
		padding: 75px 100px ;
	}
	.thank-you-modal-close {
		position: absolute;
		top: 3px;
		right: 3px ;
		width: 34px;
		height: 34px;
		background: url('../img/close-popup-thank-you.svg');
	}
	.modal-thank-you-text {
		text-align: center;
		.modal-thank-you-icon {
			max-width: 105px ;
			max-height: 105px ;
			margin: 0 0 35px 0 ;
		}
		.modal-thank-you-title {
			display: block;
			color: $black;
			font-size: 40px;
			font-weight: 700;
			line-height: 50px;
			margin: 0 0 40px 0 ;
		}
	}
	.product-info-container {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center ;
		justify-content: space-around;
		border-radius: 5px;
		border: 1px solid #eeeeee;
		background-color: #fafafa;
		padding: 18px 18px ;
		img {
			width: 100px;
			height: auto;
			margin: 0 auto 0 0 ;
		}
		.modal-thank-you__name,
		.modal-thank-you__price {
			color: $black;
			font-size: 17px;
			font-weight: 300;
			line-height: 30px;
			padding: 0 10px ;
			margin: 0 auto ;
		}

	}
	.modal-thank-you-buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		border-top: 1px solid #d8d8d8;
		padding: 25px 0 0 0 ;
		margin: 50px 0 0 0 ;
		a {
			width: 48%;
			display: block;
			text-align: center;
			border-radius: 5px;
			color: $white;
			font-family: $font ;
			font-size: 17px;
			font-weight: 700;
			text-transform: uppercase;
			padding: 16px 20px ;
			&.modal-close {
				background: $main_color ;
			}
			&.link-to-checkout {
				background: $color_2 ;
			}
		}
	}
}
.small-pop-up {
	position: relative;
	max-width: 480px ;
	.modal-offer-mini {
		width: 49%;
		position: absolute ;
		bottom: 209px;
		right: 22px;
		z-index: 100;
		padding: 30px 15px 5px 15px ;
		box-shadow: 0 11px 15px rgba(0, 0, 0, 0.1);
		background-color: #fafafa;
		@media only screen and (max-width: 767px) {
			right: 2px;
		}
		.one-button & {
			// right: auto ;
			// left: 0;
		}
		&:before {
			content: '' ;
			position: absolute;
			bottom: -11px;
			left: 0;
			right: 0;
			margin: 0 auto ;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 11.5px 11px 11.5px;
			border-color: transparent transparent #fafafa transparent;
			transform: rotate(180deg);
		}
		div.wpcf7 {
			margin: 0 0 0 0 ;
		}
		.modal-close {
			display: none;
			position: absolute;
			top: -17px;
			right: -17px;
			font-size: 0;
			width: 34px;
			height: 34px;
			background: url('../img/close-popup-thank-you.svg');
		}
	}
}

li.product .small-pop-up {
	max-width: 100%;

	.modal-offer-mini {
		width: 100%;
		top: -330px;
		left: 50%;
		transform: translateX(-50%);
		bottom: 30px;
		
	}
}
.js-single-product-attr {
	.js-attr-status {
		&--true, &--false {
			display: none;
		}
	}

	&.attr-status--true {
		.js-attr-status {
			&--true {
				display: inline-block;
			}

			&--false {
				display: none;
			}
		}
	}

	&.attr-status--false {
		.value input, .value select {
			border-color: #F00 !important;
		}

		.js-attr-status {
			&--true {
				display: none;
			}

			&--false {
				display: inline-block;
			}
		}
	}
}


@media only screen and (min-width: 768px) {
	.single-product-section,.archive-product {
		.woocommerce-breadcrumb {
			padding-left: 15px;
			padding-right: 15px;
		}
	}


	.modal {
		&[data-id="modal-offer"] {
			width: 540px;
		}
	}
}
@media only screen and (max-width: 991px) {

	.single-product-tab-section {
		.tab-box-list {
			padding: 60px 0 ;
			.two-column-row {
				&.two-column-row {
					.column {
						width: 100%;
					}
				}
			}
		}
		.tab-list {
			.tab-item {
				a {
					font-size: 20px;
					padding: 15px 60px 10px 60px ;
				}
			}
		}
	}
	.modal-thank-you {
		.modal-thank-you-container {
			padding: 40px 30px;
		}
	}
}
@media only screen and (max-width: 767px) {
	.woocommerce-breadcrumb {
		display: none;
	}
	.woocommerce {
		div.product {
			.product_title {
				font-size: 30px;
				line-height: 35px;
				margin: 0 0 15px 0 ;
			}
			div.images.woocommerce-product-gallery {
				margin: 0 0 20px 0 ;
			}
			form.cart, form.cart2 {
				margin: 0 0 70px 0 ;


				@media only screen and (max-width: 767px) {
					padding-right: 5px;
					border-top: 1px solid #eeeeee;
				}

				.variations {
					margin: 0 0 38px 0 ;
					padding: 0 28px 0 0 ;

					@media only screen and (max-width: 767px) {
						padding-right: 0;
					}
				}
			}

		}

	}
	.single-product-section {
		padding: 50px 0 0 0 ;
		.single-product-info-row {
			div.single-product-info-column.summary ,
			.single-product-gallery-column {
				width: 100%;
				float: none ;
			}
			div.single-product-info-column.summary {
				order: 2 ;
				padding-right: 25px;
			}
			div.single-product-gallery-column {
				order: 1 ;
			}
		}
		form.cart, form.cart2 {
			max-width: none ;
			.add-comment-to-order  {
				a {
					font-size: 18px;
				}
			}
			.single-product-attr_pa_leveranstid {
				margin: 0 0 35px 0 ;
				.value {
					label {
						margin: 0 0 13px 0 ;
						.single-product-attr-label {
							font-size: 15px;
							line-height: 22px ;
						}
						.single-product-attr-price {
							font-size: 15px;
						}
					}
				}
			}
			.single_variation_wrap {
				.single-product-button-row {
					.button-row-column {
						width: 100%;
						margin: 0 0 13px 0 ;
					}
				}
			}
		}
	}
	.single-product-total {
		padding: 30px 0 0 0 ;
		.label-total-exklmoms {
			font-size: 15px;
		}
	}



	.single-product-section {
		.woocommerce-breadcrumb {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
	.single-product-tab-section {
		.tab-box-list {
			padding: 35px 0 ;
			p {
				font-size: 15px;
				line-height: 24px;
				margin: 0 0 32px 0 ;
			}
		}
		.tab-list {
			.tab-item {
				& + .tab-item {
					margin: 0 0 0 3px ;
					@media only screen and (max-width: 767px) {
						margin: 4px 0 0 0 ;
					}
				}
				a {
					font-size: 17px;
					font-weight: 500;
					padding: 10px 20px 6px 20px ;
				}
			}
		}
	}
	.modal-offer {
		width: 100%;
		right: auto;
		left: 0;
		&.d-none {
			left: 100%;
			right: auto;
		}
		&__hide {
			padding: 35px 25px ;
			h3 {
				font-size: 30px;
				line-height: 35px;
				margin: 0 0 20px 0 ;
			}
			.modal-offert-text {
				padding: 0 0 1px 0 ;
				p {
					font-size: 15px;
					line-height: 24px;
				}
			}
		}
		&__btn {
			width: 160px;
			height: 50px;
			font-size: 15px;
			transform: rotate(-90deg) translateX(-30px);
		}
	}
	.modal-thank-you {
		.modal-thank-you-container {
			padding: 40px 5px;
		}
		.modal-thank-you-text {
			.modal-thank-you-icon {
				max-width: 50px;
				max-height: 50px;
				margin: 0 0 20px 0 ;
			}
			.modal-thank-you-title {
				font-size: 30px;
				line-height: 36px ;
				font-weight: 700;
				margin: 0 0 23px 0 ;
			}
		}
		.product-info-container {
			border-radius: 4px;
			padding: 8px 18px ;
			.modal-thank-you__name,
			.modal-thank-you__price {
				font-size: 15px;
				font-weight: 300;
				line-height: 24px;
				padding: 0 0 0 0 ;
			}
			img {
				order: 2 ;
				width: 58px;
			}
			.modal-thank-you__name {
				order: 1 ;
				width: 100%;
				margin: 0 0 11px 0 ;
			}
			.modal-thank-you__price {
				order: 3 ;
				margin: 0 0 0 auto ;
			}
		}
		.modal-thank-you-buttons {
			flex-wrap: wrap;
			padding: 0 20px ;
			border: none ;
			margin: 23px 0 0 0 ;
			a {
				width: 100%;
				font-size: 15px;
				padding: 18px 20px;
				& + a {
					margin: 15px 0 0 0 ;
				}
			}
		}
	}
	.small-pop-up {
		max-width: none ;
		.modal-offer-mini {
			width: 100% ;
			bottom: 174px;
		}
	}
}



@media only screen and (min-width: 641px) and (max-width: 1024px) {

}
@media only screen and (max-width: 500px) {

}


.product-tabs-p {
	margin: 0 -15px;

	@media only screen and (max-width: 767px) {
		margin: 0;
	}

	&--desktop {
		display: block;

		@media only screen and (max-width: 767px) {
			display: none !important;
		}
	}

	&--mobile {
		display: none !important;

		@media only screen and (max-width: 767px) {
			display: block !important;
		}
	}

	.tab-list {
		padding: 0;
		margin: 0;
		display: flex;
		width: 100%;
		list-style: none;
		@media only screen and (max-width: 767px) {
			flex-wrap: wrap;
		}
		li {
			display: inline-block;
			color: #000 !important;
			margin-right: 4px;
			border-radius: 0 !important;
			border: 0 !important;
			flex-grow: 1;
			max-width: 50%;

			@media only screen and (max-width: 767px) {
				margin: 0;
				max-width: none;
				width: 100%;
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				color: $main_color !important;
				padding: 11px 5px;
				display: block;
				font-size: 17px;
				min-width: 170px;
				text-align: center;
				background-color: $white !important;
				border-radius: 5px 5px 0 0 !important;
				border: 0 !important;
				overflow: hidden;

				@media only screen and (max-width: 767px) {
					min-width: 50px;
					padding: 11px 15px;
				}
			}

			&:not(.tab-item--active) a {
				background-color: $main_color !important;
				color: $white !important;
				&:hover {
					background-color: $white !important;
					color: $main_color !important;
				}
			}

			&.tab-item--active {
				position: relative;

				&::after {
					position: absolute;
					top: calc(100% + 1px);
					right: 0;
					left: 0;
					margin: auto;
					display: block;
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 5px 0 5px;
					border-color: #e85d00 transparent transparent transparent;
					line-height: 0px;
					_border-color: #e85d00 #000000 #000000 #000000;
					_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');

					display: none; // client want to hide it
				}
			}
		}
	}

	.tab-box-list {
		border-top: 0 !important;
		padding-top: 0 !important;
		padding-left: 15px;
		padding-right: 15px;

		@media (max-width: 767px) {
			padding-left: 25px;
			padding-right: 25px;
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			background-color: $white;
		}

		.two-column-row {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			.tab-box-single__inner {
				width: 50%;

				@media only screen and (max-width: 1023px) {
					width: 100%;
				}
			}
		}

	}
	
	.tab-box-single {
		&__inner {
			padding: 10px;
			background-color: #fff;
		}
	}
}

.single-product-button-row {

	.single_add_to_cart_button {
		@media only screen and (max-width: 767px) {
			width: 100% !important;
			margin-bottom: 10px !important;
		}
	}

	.button-row-column {
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
}

.single.single-product .woocommerce-product-gallery__image {
	.flex-active-slide, a {
		width: 100%;
		height: 440px;
		min-height: 440px;

		@media only screen and (max-width: 767px) {
			height: 240px;
			min-height: 240px;
			object-fit: cover;
		}

		img {
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
		}
	}
}

.woocommerce-product-gallery {
	display: flex;
	padding-right: 130px;

	@media only screen and (max-width: 767px) {
		padding-right: 86px;
	}

	.zoomImg {
		opacity: 0 !important;
	}

	.flex-viewport {
		float: inherit !important;
		display: block !important;
		height: 446px !important;

		@media only screen and (max-width: 767px) {
			height: 236px !important;
		}
	}

	.flex-control-thumbs {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0 !important;
		width: 105px;
		height: 100%;
		float: inherit !important;
		display: block !important;
		transition: top .3s;

		@media only screen and (max-width: 767px) {
			width: 80px;
		}

		&--after {
			height: auto;
			overflow: auto;
		}

		li {
			margin: 0 0 13px 0 !important;
			width: 100% !important;
			height: 80px;
			object-fit: cover;
			border: 3px solid transparent;
			transition: all 0.3s;

			@media only screen and (max-width: 767px) {
				margin-bottom: 5px !important;
			}

			img {
				width: 100% !important;
				height: 100% !important;
				display: block !important;
				object-fit: cover;
				border: 0 !important;
			}
		}

		&:not(.flex-control-thumbs--after) {
			li {
				height: calc(25% - 10px) !important;

				@media only screen and (max-width: 767px) {
					height: calc(33.33% - 4px) !important;
				}
			}
		}
	}
}

.ga-control {
	&-p {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 105px;
		height: 100%;
		overflow: hidden;

		@media only screen and (max-width: 767px) {
			width: 80px;
			height: 240px;
		}
	}

	&__top, &__down {
		width: 30px;
		height: 30px;
		display: block;
		position: absolute;
		right: 0;
		left: 0;
		margin: auto;
		/*background: #efefef;
		border-radius: 50%;
		box-shadow: 0 0 15px #000;*/
	}

	&__top {
		top: 0;

		&::after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 15px 10px;
			border-color: transparent transparent #009901 transparent;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}

	&__down {
		bottom: 1px;

		&::after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 15px 10px 0 10px;
			border-color: #009901 transparent transparent transparent;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
}