/*@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,700,700i&display=swap&subset=latin-ext');

body {
    font-family: 'Montserrat', sans-serif;
}*/

.colorYellow {
    color: #ff9933;
}
.colorWhite {
    color: #fff;
}
.colorLight {
    color: #9da1a4;
}
.colorDark {
    color: #0a2022;
}

.title {
    margin: 30px 0 0;
}
h1.title {
    font-size: 35px;
    line-height: 35px;
}
h2.title {
    font-size: 30px;
    line-height: 33px;
}
h3.title {
    font-size: 25px;
    line-height: 26px;
}
h4.title {
    font-size: 20px;
    line-height: 24px;
}

.title .colorWhite {
    font-size: 17px;
}

.content p {
    font-size: 17px;
    line-height: 24px;
    margin: 15px 0 0;
}

.btn {
    font-size: 16px;
}
.btn:hover {
    color: #fff;
}
.btnOrange {
    margin: 15px 0 0;
    background: #ff9933;
    padding: 10px 45px;
}
.btnOrange:hover {
    background: #ff8409;
}
.btnGray {
    background: #293241;
    padding: 15px;
    margin-top: 30px;
}

.thumbnail {
    margin-top: 30px;
}

.chest {
    background: #293241;
    padding: 15px 45px 45px;
}

#logo {
    padding: 90px 0 180px;
}
#logo img {
    //margin-top: 30px;
}

#banner {
    background: url(http://tm.znakomity.net/wp-content/uploads/2019/09/banner.jpg);
    background-size: cover;
    padding: 60px 30px 90px;
    margin: 0 -30px;
}
.home #banner {
    padding: 90px 30px 60px;
}

#contact {
    padding: 90px 0 120px;
    background: #fafafa;
    margin-top: 120px;
}


#for {
    margin-top: 15px;
    border: 1px solid #d0d0d0;
    padding: 15px;
    box-shadow: inset 0 -2px 0 #5d798a;
    font-size: 17px;
    border-radius: 30px;
    background: #fff;
    cursor: pointer;
}
#for svg {
    margin-left: 10px;
}

footer {
    //background: #293241;
    //margin-top: 60px;
    //padding: 30px 0;
}

#share svg {
    margin: 30px 5px 0 0;
    width: 20px;
    height: 20px;
    padding: 5px;
    background: #fff;
    color: #293241;
}

#project {
    margin-top: 60px;
    font-size: 17px;
}

.excerpt p {
    font-size: 17px;
    line-height: 24px;
    margin: 30px 0 0;
}
.excerpt ul {
    padding-top: 15px;
}
.excerpt li {
    margin-top: 15px;
}
.excerpt a {
    color: #fff;
    transition: .15s;
}
.excerpt a:hover {
    opacity: .9;
}
.excerpt svg {
    margin-right: 10px;
}

.logo {
    height: 30px;
}

#content {
    padding-top: 45px;
}

#head {
    background: url(http://tm.znakomity.net/wp-content/uploads/2019/09/head.jpg) no-repeat;
    background-size: auto 50%;
    padding: 90px 60px 60px;
    margin: 0 -30px 30px;
}

#sheet {
    background: #fafafa;
    padding: 0 30px 30px;
}

#pros {
    padding-top: 30px;
}








@media (min-width: 576px) {



}

@media (min-width: 768px) {



}

@media (min-width: 992px) {
    h1.title {
        font-size: 50px;
        line-height: 47px;
    }
    h2.title {
        font-size: 35px;
        line-height: 35px;
    }
    h3.title {
        font-size: 26px;
        line-height: 26px;
    }
    h4.title {
        font-size: 22px;
        line-height: 22px;
    }
    .content p,

    .title .colorWhite,
    .form-control,
    label,
    #project,
    .excerpt p {
        font-size: 18px;
    }
    .home #banner {
        padding: 210px 60px 180px;
    }
    .logo {
        height: auto;
    }

    #head {
        background-size: cover;
        margin: 0 0 30px;
    }
    #sheet {
        background: none;
    }







}