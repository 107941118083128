
.header {
	position: relative;
	z-index: 101 ;
	background-color: $orange;
}
.custom-logo-link {
	img {
		vertical-align: top;
	}
}
.cart-link {
	position: relative;
	display: flex;
	justify-content: flex-end;
	&__counter {
		position: absolute;
		width: 26px;
		height: 26px;
		border: 2px solid $white;
		background-color: $black;
		color: $white;
		font-family: $font ;
		font-size: 14px;
		font-weight: 500;
		border-radius: 50% ;
		text-align: center;
		line-height: 22px ;
		top: -13px;
		right: -17px;
	}
}
.header-cart-button {
	display: none;
	&.active {
		display: inline-block;

		@media only screen and (max-width: 767px) {
			display: none !important;
		}
	}
}

@media only screen and (min-width: 768px) {

	.burger-column {
		display: none;
	}
	.cart-link {
		align-self: center;
	}
	.menu-column {
		margin-left: auto;
	}
	#nav-icon2 {
		display: none;
	}
	.header-desktop__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center ;
	}
	.header {
		padding: 20px 0 ;
		transition: all .4s ease-in-out;
		.header-logo {
			img {
				transition: all .4s ease-in-out ;
				height: 40px ;
			}
		}
		&.stick {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			padding: 8px 0;
			.header-logo {
				img {
					height: 30px ;
				}
			}
		}
	}
	// .top-bar-logos {

	// }
	.top-bar {
		.menu-contact-info {
			display: none ;
		}
		.mobile-info {
			display: none;
		}
		.header-menu {
			font-size: 0;
			padding: 0 0 0 0;
			> li {
				display: inline-block;
				vertical-align: top;
				&.text-align-right {
					text-align: right;
				}
				& + li {
					margin: 0 0 0 30px ;
				}
				> a {
					color: $white;
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;
					display: inline-block;
					padding: 10px 0 ;
					position: relative;

					&::after {
						content: '';
						position: absolute;
						width: 0;
						height: 2px;
						bottom: 2px;
						left: 0;
						right: 0;
						display: block;
						background-color: $white;
						transition: .3s;
					}

					&:hover {
						color: $white;
						&::after {
							width: 100%;
						}
					}
				}
				&.current-menu-item,&.current-menu-parent, &.current_page_ancestor, &.current_page_parent {
					> a {

						&::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 2px;
							bottom: 2px;
							left: 0;
							right: 0;
							display: block;
							background-color: $white;
						}
					}
				}
				.submenu {
					border-radius: 8px ;
					min-width: 235px ;
					position: absolute;
					box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
					border: 1px solid #c0c1c6;
					background-color: $white;
					display: none;
					padding: 0 0 0 0 ;
					margin: 0 0 0 -10px;
					&:before {
						content: '' ;
						position: absolute;
						width: 10px;
						height: 10px;
						border-radius: 3px;
						border-right: 1px solid  #c0c1c6;
						border-top: 1px solid  #c0c1c6;
						transform: rotate(-45deg);
						right: 17px;
						top: -6px;
						background: white ;
						pointer-events: none ;
					}
					li {
						&:first-child {
							overflow: hidden;
							border-radius: 8px 8px 0 0 ;
						}
						&:last-child {
							overflow: hidden;
							border-radius: 0 0 8px 8px  ;

						}
						& + li {
							&:before {
								content: '' ;
								display: block;
								margin: 0 7px ;
								border-top: 1px solid #E1E2E4 ;
							}
						}
						a {
							display: block;
							padding: 14px 25px 14px 32px;
							color: #767c87;
							font-size: 14px;
							font-weight: 400;
							line-height: 15.4px;
							position: relative;
							&:before {
								content: '' ;
								position: absolute;
								width: 4px;
								height: 0;
								background: $main_color;
								left: 0;
								bottom: 0;
								transition: all .4s cubic-bezier(.71,.91,.61,1.43);
							}
							&:hover {
								color: $main_color ;
								&:before {
									height: 100%;
								}
							}
						}
						&.current-menu-item,&.current-menu-parent, &.current_page_ancestor, &.current_page_parent {
							> a {
								color: $main_color ;
								&:before {
									height: 100%;
								}
							}
						}
					}
				}
			}
			li {
				&.has-dropdown {
					&:hover {
						.submenu {
							display: block;
						}
					}
					> a {
						position: relative;
						padding: 10px 25px 10px 0 ;
						&:after {
							content: '' ;
							position: absolute;
							right: 0;
							top: 0px;
							bottom: 0;
							left: auto;
							margin: auto ;
							width: 14px;
							height: 10px;
							background: url('../img/arr-down.svg') no-repeat 0 0 ;
							background-size: 100% auto ;
						}
					}
					.open-submenu {
						display: none;
					}
					a {

					}
				}
			}
		}
	}
}

.top-bar {
	@extend %bgcover ;
}
@media only screen and (max-width: 1199px) {
	.top-bar .header-menu>li+li {
		margin: 0 0 0 20px ;
	}
	.top-bar .header-menu li.has-dropdown>a {
		padding: 10px 35px 10px 0 ;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
	.header-logo {
		width: 100%;
		text-align: center;
	}
	.menu-column {
		width: 100%;
		text-align: center;
	}
	.header-desktop__row {
		justify-content: center;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.header-desktop__row {
		justify-content: center;
	}
	.header-logo {
		width: 100%;
		text-align: center;
		.custom-logo-link {
			display: inline-block;
			margin: 0 0 15px 0 ;
		}
	}
	.menu-column {
		margin-left: unset;
	}
}
@media only screen and (max-width: 767px) {
	.header {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		background: $white ;
		min-height: 74px ;
		padding: 20px 0 15px 0;
		background: #d94d02;
		.menu-column {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.burger-column {
		// margin-left: auto;
	}
	.header-logo {
		img {
			min-width: 115px ;
			max-height: 37px ;
		}
	}
	body {
		padding-top: 74px   ;
	}
	.header-desktop__row {
		display: flex;
		align-items: center ;
		justify-content: space-between;
	}
	.cart-link {
		margin-left: auto;
	}
	.column--cart {
		margin-left: auto;
		flex-shrink: 0;
	}
	.top-bar {
		position: fixed;
		z-index: 1000;
		top: 74px;
		left: -100%;
		bottom: 0;
		width: 100%;
		background-image: url('../img/mobile-menu-bg.jpg')  ;
		transition: all .4s ease-in-out ;
		text-align: center;
		padding: 35px 25px 25px 25px ;
		max-height: calc(100vh - 74px ) ;
		overflow: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&.open {
			left: 0;
		}
		.mobile-info {
			display: block;
			.custom-button {
				min-width: 160px ;
				margin: 3px 0 ;
				color: $white;
				font-size: 18px;
				font-weight: 400;
			}
		}
		.menu-contact-info {
			display: block;
			padding: 10px 10px 0 10px;
			img {
				max-width: 103px ;
				max-height: 115px ;
			}
			.menu-contact {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin: 20px 0 0 0 ;
				a {
					color: $white;
					font-size: 17px;
					line-height: 24px;
				}
			}
		}
		.header-menu {
			font-size: 0;
			padding: 0 0 12px 0 ;
			> li {
				display: block;
				font-size: 0;
				br {
					display: none;
				}
				& + li {
					margin: 0 0 0 0px ;
				}
				> a {
					display: inline-block;
					color: $white;
					font-size: 21px;
					font-weight: 700;
					line-height: 34px;
                    // &:hover {
                    //     color: $main_color ;
                    // }
                }
                // &.current-menu-item, &.current_page_ancestor, &.current_page_parent {
                //  > a {
                //      color: $main_color ;
                //  }
                // }
                .submenu {
                	display: none;
                	padding: 0 0 10px 0 ;
                	li {
                		a {
                			color: $white;
                			font-size: 17px;
                			letter-spacing: -0.22px;
                			line-height: 30px;
                		}
                        // &.current-menu-item, &.current_page_ancestor, &.current_page_parent {
                        //  > a {
                        //      color: $main_color ;
                        //  }
                        // }
                    }
                }
            }
            li {
            	&.has-dropdown {

            		> a {
            			padding: 0 0 0 25px ;
            			position: relative;
            		}
            		.open-submenu {
            			display: inline-block;
            			width: 20px;
            			height: 20px;
            			margin: 0 0 0 5px ;
            			&:after {
            				content: '' ;
            				display: inline-block;
            				width: 16px;
            				height: 11px;
            				background: url('../img/arr-down-orange.svg') no-repeat 0 0 ;
            				background-size: 100% auto ;
            				transition: all .3s ease-in-out ;
            			}
            			&.active {
            				&:after {
            					transform: rotate(180deg);
            				}
            			}
            		}
            	}
            }
        }
    }


    #nav-icon2 {
    	width: 26px;
    	height: 16px;
    	position: relative;
    	transform: rotate(0deg);
    	transition: .5s ease-in-out;
    	cursor: pointer;
    	span {
    		display: block;
    		position: absolute;
    		height: 2px;
    		width: 50%;
    		background: $white;
    		opacity: 1;
    		transform: rotate(0deg);
    		transition: .25s ease-in-out;
    		&:nth-child(even) {
    			left: 50%;
    		}
    		&:nth-child(odd) {
    			left:0px;
    		}
    		&:nth-child(1),&:nth-child(2) {
    			top: 0;
    		}
    		&:nth-child(3),&:nth-child(4) {
    			top: 7px;
    		}
    		&:nth-child(5),&:nth-child(6) {
    			top: 14px;
    		}
    	}
    	&.open {
    		span {
    			&:nth-child(1) {
    				transform: rotate(45deg);
    				left: 0px;
    				top: 4px;
    			}
    			&:nth-child(2) {
    				transform: rotate(-45deg);
    				left: calc(50% - 5px);
    				top: 4px;
    			}
    			&:nth-child(3) {
    				left: -50%;
    				opacity: 0;
    			}
    			&:nth-child(4) {
    				left: 100%;
    				opacity: 0;
    			}
    			&:nth-child(5) {
    				transform: rotate(-45deg);
    				left: 0px;
    				top: 12px;
    			}
    			&:nth-child(6) {
    				transform: rotate(45deg);
    				left: calc(50% - 5px);
    				top: 12px;
    			}
    		}
    	}
    }
}

.column--cart {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;

	.shop img {
		width: auto;
		max-height: 25px;
	}

	@media (max-width: 450px) {
		padding-right: 0;
		padding-left: 0;
	}
}