
.top-section {
	padding: 24px 0 0 0 ;
	@extend %bgcover ;
	.top-section-title {
		color: $white;
		font-family: $font-lobster ;
		font-size: 84px;
		line-height: 84px ;
		font-weight: 400;
		margin: 0 0 25px 0 ;
	}
	.top-section-row {
		display: flex;
		justify-content: flex-end;
	}
	.top-section-text-column {
		padding-top: 35px;

		@media (min-width: 992px) and (max-width: 1107px) {
			padding-top: 0;
		}
	}
	.top-section-image-column {
		padding-left: 45px;
		img {
			max-width: 280px;
			max-height: 256px;
			display: block;
		}
	}
}
.top-section-list {
	list-style: none ;
	margin: 0 0 0 0 ;
	padding: 0;

	@media (min-width: 991px) and (max-width: 1102px) {
		transform: translateX(-12px);
	}

	li {
		display: inline-block;
		vertical-align: top;
		padding: 0 0 0 25px ;
		color: $white ;
		font-weight: 400;
		//font-size: 24px;
		font-size: 14px;
		background: url('../img/list_check.svg') no-repeat 0 5px ;
		margin: 0 12px;
		background-size: 16px 12px;

		@media (min-width: 1103px) {
			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.full-width-image-section {
	img {
		width: 100%;
	}
}

.logos-section {
	padding: 50px 0 ;
	.logos-section-row {
		display: flex;
		flex-wrap: wrap;
		.column {
			padding-top: 10px;
			width: 100%;
			&:nth-child(odd) {
				border-right: 1px solid #d8d8d8 ;
			}
		}
		.logos-title {
			text-align: center;
			color: $main_color ;
			font-family: $font-lobster ;
			font-weight: 400;
			font-size: 40px ;
			line-height: 46px ;
			margin: 0 0 35px 0 ;
		}
		.logos-list {
			list-style: none ;
			display: flex;
			flex-wrap: wrap;
			align-items: center ;
			padding: 0 0 0 0 ;
			margin: 0 0 0 0 ;
			li {
				padding: 5px 3px;
				text-align: center;
				width: calc(100% / 6);
				margin: 20px 0 ;
				@media (max-width:575px){
					width: calc(100% / 3);
				}
			}
		}
	}
}

.category-section {
	padding: 110px 0 20px 0 ;
	.category-section-title {
		color: $main_color;
		font-family: $font-lobster;
		font-size: 60px;
		font-weight: 400;
		line-height: 66px ;
		margin: 0 0 20px 0 
	}
	.category-section-small-title{
		font-size: 20px;
		font-family: Helvetica,sans-serif;
		font-weight: 400;
	}
	.category-section-text {
		padding-bottom: 30px;
		h4 {
			font-weight: 400;
			font-size: 27px;
			line-height: 33px ;
			text-transform: uppercase;
			margin: 0 0 23px 0 ;
		}
		p {
			color: #a0a0a0;
			font-size: 16px;
			line-height: 29px;
		}
	}
}
.category-list {
	display: flex;
	flex-wrap: wrap;
	list-style: none ;
	margin: 0 0 0 0 ;
	padding: 0 0 0 0 ;
	li {
		width: 25%;
		margin: 0 0 30px 0 ;
		position: relative;
		.category-list-image-container {
			overflow: hidden;
			position: relative;

			.transparent_link {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
		.category-list-image {
			padding: 100% 0 0 0 ;
			@extend %bgcover ;
			transition: .5s;
		}
		.custom-button {
			left: 39px ;
			right: 39px ;
			bottom: 10px ;
			position: absolute !important;
			color: $main_color;
			font-family: $font-lobster;
			font-size: 25px;
			line-height: 25px ;
		}

		&:hover {
			.category-list-image {
				transform: scale(1.2);
			}
		}
	}
}

.home-inspiration-section {
	padding: 90px 0 ;
	.home-inspiration-page-title {
		color: $main_color ;
		font-family: $font-lobster ;
		font-size: 40px;
		margin: 0 0 35px 0 ;
		font-weight: 400;
	}
}

@media only screen and (max-width: 767px) {
	.top-section {
		padding: 62px 0 0px 0 ;
		.top-section-row {
			flex-wrap: wrap;
			position: relative;
			padding-bottom: 92px;
		}
		.top-section-text-column {
			width: 100%;
			padding-top: 0;
		}
		.top-section-image-column {
			position: absolute;
			right: 0px;
			bottom: 0;
			img {
				max-width: 120px ;
			}
		}
		.top-section-title {
			font-size: 60px;
			line-height: 66px;
			text-align: center;
			margin: 0 0 23px 0 ;
		}
	}
	.top-section-list {
		padding: 0 0 0 0px ;
		li {
			display: block;
			padding: 0 0 0 35px ;
			margin: 0 0 16px 0 ;
			//font-size: 19px;
			font-size: 17px;
			background-size: 18px auto ;
		}
	}
	.logos-section {
		padding: 30px 0 ;
		.logos-section-row {
			.column {
				width: 100%;
				padding-top: 0;
				margin-bottom: 30px;
				border: none;
				& + .column {
					&:before {
						content: '' ;
						display: block;
						height: 1px;
						background: #d8d8d8;
						margin: 0 0 40px 0 ;
					}
				}
			}
			.logos-title {
				font-size: 25px;
				line-height: 31px ;
				margin: 0 0 20px 0 ;
			}
			.logos-list {
				li {
					margin: 5px 0 ;
					img {
						max-width: 90% ;
						max-height: 25px ;
					}
				}
			}
		}
	}
	.category-section {
		padding: 85px 0 45px 0 ;
		.category-section-title {
			font-size: 40px ;
			line-height: 40px ;
			margin: 0 0 45px 0 ;
		}
		.category-section-text {
			padding-bottom: 30px;
			h4 {
				font-size: 17px;
				line-height: 20px;
			}
			p {
				font-size: 15px;
				line-height: 22px;
			}
		}
	}
	.category-list {
		li {
			width: 50%;
			margin: 0 0 20px 0 ;
			.category-list-image {
				padding: 87% 0 0 0 ;
			}
			.custom-button {
				left: 52px ;
				right: 52px ;
			}
		}
	}

	.home-inspiration-section {
		padding: 100px 0 ;
		.home-inspiration-page-title {
			font-size: 25px;
			line-height: 31px ;
			margin: 0 0 40px 0 ;
		}
	}
	.load-more-row {
		padding: 30px 0 0 0 ;
	}

}
@media only screen and (max-width: 575px) {
	.category-list {
		li {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.category-list {
		li {
			width: 50%;
		}
	}
	.top-section {
		.top-section-title {
			font-size: 60px;
			line-height: 66px;
		}
	}
	.top-section-list {
		padding: 0 0 40px 0;
		li {
			display: block;
			margin: 0 0 15px 0;
		}
	}
	.category-section {
		.category-section-title {
			font-size: 50px;
			line-height: 56px ;
		}
		.category-section-text {
			h4 {
				font-size: 24px;
				line-height: 30px ;
			}
		}
	}
}
